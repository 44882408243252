import { Link } from "react-router-dom";
import headInfo from "../../../imgs/head-info.svg";
export default function Info({ event }) {
  function formatDate(dateString) {
    const currentDate = new Date(dateString);
    currentDate.setDate(currentDate.getDate() - 1);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return currentDate.toLocaleDateString("en-US", options);
  }
  const token = localStorage.getItem("user-login-token");
  return (
    <div className="hero-info">
      <img src={headInfo} alt="info" />
      <h3 className="name">
        <span style={{ color: "white" }}>{event?.name.split(" ")[0]}</span>
        <span style={{ color: "#FFD795" }}>
          {event?.name.split(" ").slice(1)}
        </span>
      </h3>
      <div className="date d-flex  gap-2 align-items-center">
        <svg
          width="21"
          height="22"
          viewBox="0 0 21 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.2824 1.29395H16.4294V3.23512C16.4294 3.62336 16.1206 3.88218 15.8118 3.88218C15.503 3.88218 15.1941 3.62336 15.1941 3.23512V1.29395H5.31179V3.23512C5.31179 3.62336 5.00297 3.88218 4.69415 3.88218C4.38532 3.88218 4.0765 3.62336 4.0765 3.23512V1.29395H2.22356C1.29709 1.29395 0.617676 2.13512 0.617676 3.23512V5.56453H20.3824V3.23512C20.3824 2.13512 19.2706 1.29395 18.2824 1.29395ZM0.617676 6.92336V18.7645C0.617676 19.9292 1.29709 20.7057 2.28532 20.7057H18.3441C19.3324 20.7057 20.4441 19.8645 20.4441 18.7645V6.92336H0.617676ZM6.11473 17.7939H4.63238C4.38532 17.7939 4.13826 17.5998 4.13826 17.2763V15.6587C4.13826 15.3998 4.32356 15.141 4.63238 15.141H6.1765C6.42356 15.141 6.67062 15.3351 6.67062 15.6587V17.2763C6.60885 17.5998 6.42356 17.7939 6.11473 17.7939ZM6.11473 11.9704H4.63238C4.38532 11.9704 4.13826 11.7763 4.13826 11.4528V9.83512C4.13826 9.5763 4.32356 9.31747 4.63238 9.31747H6.1765C6.42356 9.31747 6.67062 9.51159 6.67062 9.83512V11.4528C6.60885 11.7763 6.42356 11.9704 6.11473 11.9704ZM11.0559 17.7939H9.51179C9.26473 17.7939 9.01768 17.5998 9.01768 17.2763V15.6587C9.01768 15.3998 9.20297 15.141 9.51179 15.141H11.0559C11.303 15.141 11.55 15.3351 11.55 15.6587V17.2763C11.55 17.5998 11.3647 17.7939 11.0559 17.7939ZM11.0559 11.9704H9.51179C9.26473 11.9704 9.01768 11.7763 9.01768 11.4528V9.83512C9.01768 9.5763 9.20297 9.31747 9.51179 9.31747H11.0559C11.303 9.31747 11.55 9.51159 11.55 9.83512V11.4528C11.55 11.7763 11.3647 11.9704 11.0559 11.9704ZM15.9971 17.7939H14.453C14.2059 17.7939 13.9589 17.5998 13.9589 17.2763V15.6587C13.9589 15.3998 14.1441 15.141 14.453 15.141H15.9971C16.2441 15.141 16.4912 15.3351 16.4912 15.6587V17.2763C16.4912 17.5998 16.3059 17.7939 15.9971 17.7939ZM15.9971 11.9704H14.453C14.2059 11.9704 13.9589 11.7763 13.9589 11.4528V9.83512C13.9589 9.5763 14.1441 9.31747 14.453 9.31747H15.9971C16.2441 9.31747 16.4912 9.51159 16.4912 9.83512V11.4528C16.4912 11.7763 16.3059 11.9704 15.9971 11.9704Z"
            fill="white"
          />
        </svg>
        <p className="mx-3 my-0">{formatDate(event?.date)}</p>
      </div>
      <div className="location d-flex  gap-2 align-items-center">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_156_730)">
            <path
              d="M11 0C7.13432 0 3.91016 3.11395 3.91016 7.08984C3.91016 8.60243 4.36468 9.95122 5.23699 11.2151L10.4574 19.3611C10.7107 19.7571 11.2898 19.7563 11.5426 19.3611L16.7857 11.1874C17.6392 9.98078 18.0898 8.56397 18.0898 7.08984C18.0898 3.1805 14.9093 0 11 0ZM11 10.3125C9.22311 10.3125 7.77734 8.86673 7.77734 7.08984C7.77734 5.31296 9.22311 3.86719 11 3.86719C12.7769 3.86719 14.2227 5.31296 14.2227 7.08984C14.2227 8.86673 12.7769 10.3125 11 10.3125Z"
              fill="white"
            />
            <path
              d="M16.0387 14.811L12.7932 19.8851C11.9531 21.1949 10.0422 21.1906 9.20614 19.8863L5.95538 14.8124C3.09521 15.4737 1.33203 16.6851 1.33203 18.1327C1.33203 20.6448 6.31331 21.9999 11 21.9999C15.6867 21.9999 20.668 20.6448 20.668 18.1327C20.668 16.684 18.9023 15.472 16.0387 14.811Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_156_730">
              <rect width="22" height="22" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <p className="mx-3 my-0">{event?.city}</p>
      </div>
      <div className="btn_dev  text-center d-flex gap-5 flex-wrap mt-3">
        <Link to={token ? "/event-registeration" : "/login"} className="">
          <span style={{ visibility: "hidden" }}>ss</span> Register
          <span style={{ visibility: "hidden" }}>ss</span>
        </Link>
        <Link to={token ? "/Hackathon" : "/login"}>Hackathon</Link>
      </div>
    </div>
    // <div className="row" style={{ direction: "ltr" }}>
    //   <div className="col-md-7 empty_side"></div>
    //   <div
    //     className="col-md-5"

    //   >
    //     <div className="Info_side">
    //       <h3>{event?.name}</h3>
    //       <p>عمل اليوم تجسيد للمستقبل</p>

    //       <h6>{event?.title.toUpperCase()}</h6>

    //       <div className="d-flex date_location align-items-center justify-content-center my-4">
    // <div className="date d-flex  justify-content-evenly">
    //   <svg
    //     width="21"
    //     height="22"
    //     viewBox="0 0 21 22"
    //     fill="none"
    //     xmlns="http://www.w3.org/2000/svg"
    //   >
    //     <path
    //       d="M18.2824 1.29395H16.4294V3.23512C16.4294 3.62336 16.1206 3.88218 15.8118 3.88218C15.503 3.88218 15.1941 3.62336 15.1941 3.23512V1.29395H5.31179V3.23512C5.31179 3.62336 5.00297 3.88218 4.69415 3.88218C4.38532 3.88218 4.0765 3.62336 4.0765 3.23512V1.29395H2.22356C1.29709 1.29395 0.617676 2.13512 0.617676 3.23512V5.56453H20.3824V3.23512C20.3824 2.13512 19.2706 1.29395 18.2824 1.29395ZM0.617676 6.92336V18.7645C0.617676 19.9292 1.29709 20.7057 2.28532 20.7057H18.3441C19.3324 20.7057 20.4441 19.8645 20.4441 18.7645V6.92336H0.617676ZM6.11473 17.7939H4.63238C4.38532 17.7939 4.13826 17.5998 4.13826 17.2763V15.6587C4.13826 15.3998 4.32356 15.141 4.63238 15.141H6.1765C6.42356 15.141 6.67062 15.3351 6.67062 15.6587V17.2763C6.60885 17.5998 6.42356 17.7939 6.11473 17.7939ZM6.11473 11.9704H4.63238C4.38532 11.9704 4.13826 11.7763 4.13826 11.4528V9.83512C4.13826 9.5763 4.32356 9.31747 4.63238 9.31747H6.1765C6.42356 9.31747 6.67062 9.51159 6.67062 9.83512V11.4528C6.60885 11.7763 6.42356 11.9704 6.11473 11.9704ZM11.0559 17.7939H9.51179C9.26473 17.7939 9.01768 17.5998 9.01768 17.2763V15.6587C9.01768 15.3998 9.20297 15.141 9.51179 15.141H11.0559C11.303 15.141 11.55 15.3351 11.55 15.6587V17.2763C11.55 17.5998 11.3647 17.7939 11.0559 17.7939ZM11.0559 11.9704H9.51179C9.26473 11.9704 9.01768 11.7763 9.01768 11.4528V9.83512C9.01768 9.5763 9.20297 9.31747 9.51179 9.31747H11.0559C11.303 9.31747 11.55 9.51159 11.55 9.83512V11.4528C11.55 11.7763 11.3647 11.9704 11.0559 11.9704ZM15.9971 17.7939H14.453C14.2059 17.7939 13.9589 17.5998 13.9589 17.2763V15.6587C13.9589 15.3998 14.1441 15.141 14.453 15.141H15.9971C16.2441 15.141 16.4912 15.3351 16.4912 15.6587V17.2763C16.4912 17.5998 16.3059 17.7939 15.9971 17.7939ZM15.9971 11.9704H14.453C14.2059 11.9704 13.9589 11.7763 13.9589 11.4528V9.83512C13.9589 9.5763 14.1441 9.31747 14.453 9.31747H15.9971C16.2441 9.31747 16.4912 9.51159 16.4912 9.83512V11.4528C16.4912 11.7763 16.3059 11.9704 15.9971 11.9704Z"
    //       fill="white"
    //     />
    //   </svg>
    //   <p className="mx-3">{formatDate(event?.date)}</p>
    // </div>
    // <div className="location d-flex  justify-content-evenly">
    //   <svg
    //     width="22"
    //     height="22"
    //     viewBox="0 0 22 22"
    //     fill="none"
    //     xmlns="http://www.w3.org/2000/svg"
    //   >
    //     <g clipPath="url(#clip0_156_730)">
    //       <path
    //         d="M11 0C7.13432 0 3.91016 3.11395 3.91016 7.08984C3.91016 8.60243 4.36468 9.95122 5.23699 11.2151L10.4574 19.3611C10.7107 19.7571 11.2898 19.7563 11.5426 19.3611L16.7857 11.1874C17.6392 9.98078 18.0898 8.56397 18.0898 7.08984C18.0898 3.1805 14.9093 0 11 0ZM11 10.3125C9.22311 10.3125 7.77734 8.86673 7.77734 7.08984C7.77734 5.31296 9.22311 3.86719 11 3.86719C12.7769 3.86719 14.2227 5.31296 14.2227 7.08984C14.2227 8.86673 12.7769 10.3125 11 10.3125Z"
    //         fill="white"
    //       />
    //       <path
    //         d="M16.0387 14.811L12.7932 19.8851C11.9531 21.1949 10.0422 21.1906 9.20614 19.8863L5.95538 14.8124C3.09521 15.4737 1.33203 16.6851 1.33203 18.1327C1.33203 20.6448 6.31331 21.9999 11 21.9999C15.6867 21.9999 20.668 20.6448 20.668 18.1327C20.668 16.684 18.9023 15.472 16.0387 14.811Z"
    //         fill="white"
    //       />
    //     </g>
    //     <defs>
    //       <clipPath id="clip0_156_730">
    //         <rect width="22" height="22" fill="white" />
    //       </clipPath>
    //     </defs>
    //   </svg>
    //   <p className="mx-3">{event?.city}</p>
    // </div>
    //       </div>

    //       <div className="btn_dev my-5 text-center w-100">
    //         <Link to="/Registration">
    //           <button disabled>Register Closed</button>
    //         </Link>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
