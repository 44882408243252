import React, { useEffect, useState } from "react";
import Calender from "../../imgs/calendar_icon.svg";
import Clock from "../../imgs/clock_icon.svg";
import { Link } from "react-router-dom";
import Location from "../../imgs/location_icon.svg";
import "./event.css";
import axios from "axios";
import { Skeleton } from "@mui/material";

const Event = () => {
  const [event, setEvent] = useState();
  function formatDate(dateString) {
    const currentDate = new Date(dateString);
    currentDate.setDate(currentDate.getDate() - 1);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return currentDate.toLocaleDateString("en-US", options);
  }
  const formatTime = (from, to) => {
    const format = (num) => (num < 10 ? `0${num}` : num);
    const convertTo12HourFormat = (hours, minutes) => {
      const period = hours >= 12 ? "PM" : "AM";
      const adjustedHours = hours % 12 || 12;
      return `${adjustedHours}:${format(minutes)} ${period}`;
    };
    const fromHours = Math.floor(from);
    const fromMinutes = (from - fromHours) * 60;
    const toHours = Math.floor(to);
    const toMinutes = (to - toHours) * 60;
    return `${convertTo12HourFormat(
      fromHours,
      fromMinutes
    )} – ${convertTo12HourFormat(toHours, toMinutes)}`;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchEvent = async () => {
      const apiUrl = `${process.env.REACT_APP_API_URL}/event/active`;
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",

          },
        });
        setEvent(response.data.result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchEvent();
  }, []);

  return (
    <div className="event_section">
      <div className="container py-5">
        <div className="Title_img my-2 p-5">
          <h2 className="mt-5 py-5">{event?.name}</h2>
        </div>
        {/* ===info */}
        <div className="row my-5">
          <div className="col-md-8">
            <div className="data">
              <h5 className="my-3">About Event</h5>
             {event? <p>
                Dear Applicant,
                <br />
                {event?.details}
              </p>:<><Skeleton variant="text" sx={{ fontSize: '1.2rem' }} /><Skeleton variant="text" sx={{ fontSize: '1.2rem' }} /><Skeleton variant="text" sx={{ fontSize: '1.2rem' }} /></>
            }
            </div>
          </div>
          <div className="col-md-4">
            <div className="date_time">
              <div className="title py-4  text-center">Date and Time</div>
              <div className="info_link py-4 px-5">
                <div className="d-flex my-4">
                  {/* Calender */}
                  <img src={Calender} alt=".." className="" />
                  <label className="mx-2 w-100">{event?formatDate(event?.date):<Skeleton variant="text" width={"100%"} sx={{ fontSize: '1.2rem' }} />}</label>
                </div>
                <div className="d-flex my-4">
                  <img src={Clock} alt=".." className="" />

                  <label className="mx-2 w-100">
                    {event?formatTime(+event?.startTime, +event?.endTime):<Skeleton variant="text" width={"100%"} sx={{ fontSize: '1.2rem' }} />}
                  </label>
                </div>
                <div className="d-flex my-4">
                  <img src={Location} alt=".." className="" />

                  <label className="mx-2 w-100">
                    {" "}
                    {event? event?.city?.charAt(0).toUpperCase() + event?.city?.slice(1): <Skeleton variant="text" width={"100%"} sx={{ fontSize: '1.2rem' }} />}
                  </label>
                </div>
                <div className="btn_div my-3 text-center">
                 {event? <Link to="/Registration">
                    <button className="btn_register" disabled>Register Closed</button>
                  </Link>:<Skeleton variant="text" sx={{ fontSize: '1.2rem' }} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------Agenda=============== */}
  {(event?.agenda&&event?.agenda.length>0)&&    <div className="agenda_section py-4" style={{ direction: "ltr" }}>
        <div className="container">
          <h3 className="my-5">EVENT AGENDA</h3>
          {/* ----------------Info------------ */}
 
          {/* 6 */}
        {event?.agenda?.map((e)=>{
            return  <div className="row my-4">
            <div className="col-md-3">
              <div className="d-flex align-items-center">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_704_692)">
                    <path
                      d="M10 0.625C4.83063 0.625 0.625 4.83063 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83063 15.1694 0.625 10 0.625ZM14.1919 14.1919C14.07 14.3138 13.91 14.375 13.75 14.375C13.59 14.375 13.43 14.3138 13.3081 14.1919L9.55813 10.4419C9.44063 10.3244 9.375 10.1656 9.375 10V5C9.375 4.655 9.655 4.375 10 4.375C10.345 4.375 10.625 4.655 10.625 5V9.74125L14.1919 13.3081C14.4363 13.5525 14.4363 13.9475 14.1919 14.1919Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_704_692">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className="mx-2">{formatTime(e.from,e.to)}</span>
              </div>
            </div>
            <div className="col-md-9">
              <h6 className="title_lable">
               {e.title}
              </h6>
              <label className="text_blue">{e.isOnline?"Will join online":"Will join offline"}</label>
              <div className="d-flex align-items-start my-2">
                <svg
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.4334 8.07773C17.3187 3.60508 13.4533 0 8.71795 0C3.91077 0 0 3.71523 0 8.28205C0 10.1321 0.622001 11.4892 1.22344 12.8016C1.88251 14.2398 2.5641 15.727 2.5641 18.0256V19H12.8205V17.3569L16.3101 18.0163C16.4613 18.0444 16.6168 18.0075 16.7355 17.915C16.8542 17.8225 16.9231 17.6843 16.9231 17.5385V14.6154C16.9231 14.4862 16.869 14.3623 16.7728 14.2709L15.597 13.1538H16.9231C17.2063 13.1538 17.4359 12.9357 17.4359 12.6667V11.2051H19.4872C19.6945 11.2051 19.8816 11.0864 19.9609 10.9044C20.0403 10.7222 19.9965 10.5129 19.8498 10.3735L17.4334 8.07773Z"
                    fill="white"
                  />
                </svg>
                <div>
                  <span className="mx-2 d-block">{e.presenter}</span>
                  <p className="mx-2 mb-0">
                   {e.jobDescription}
                  </p>
              
                </div>
              </div>

            </div>
          </div>
        }) 
        }
        </div>
      </div>}
    </div>
  );
};

export default Event;
