import { Link } from "react-router-dom";
import "./Hackathon.css";
export default function Hackathon() {
  return (
    <div className="Hackathon d-flex align-items-center">
      <div className="container">
        <h2>Hackathon </h2>
        <p>
          Lorem ipsum dolor sit amet. Qui tempore tempore id vero aliquid nam
          autem repellendus aut dolores eaque. Eos quod quia ea nihil rerum qui
          ipsam soluta qui incidunt quasi aut impedit voluptates et distinctio
          corrupti.
        </p>
        <div className="btn_dev">
          <Link to="/Registration" className="">
            Register
          </Link>
        </div>
      </div>
    </div>
  );
}
