import axios from "axios";
import { useEffect, useState } from "react";
import "./prevEvents.css";
import eventImg from "../../../imgs/event.jpeg";
import { Skeleton } from "@mui/material";
export default function PreviousEvents() {
  const [events, setEvents] = useState();
  useEffect(() => {
    const fetchEvents = async () => {
      fetch("https://modnw.smart.sa/api/admin-dashboard/event?page=1&limit=4", {
        headers: {
          "Content-Type": "application/json",
          token: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2ZDk5ZGNiOTExZDg3MmQ5ODhmZTg2ZiIsImVtYWlsIjoic3VwZXJAZ21haWwuY29tIiwicm9sZSI6InN1cGVyX2FkbWluIiwiaWF0IjoxNzI2MTIzNDE0LCJleHAiOjE3MjYyMDk4MTR9.q783qpzBzAH2pAQR7nNJuUDRZH95ISWiWB-N6MA8_GA`,
        },
      })
        .then((res) => res.json())
        .then((data) => setEvents(data.result))
        .catch((err) => {
          console.log(err);
        });
    };
    fetchEvents();
  }, []);
  function formatDate(dateString) {
    const currentDate = new Date(dateString);
    currentDate.setDate(currentDate.getDate() - 1);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return currentDate.toLocaleDateString("en-US", options);
  }
  return (
    <div className="container prevEvents">
      <h3>
        Previous <span>Events</span>
      </h3>
      <div className="d-flex justify-content-between flex-wrap gap-5">
        {events?.map((e) => {
          return (
            <div className=" card-event d-flex gap-4 flex-fill ">
              <div className="img"></div>
              <div
                className="d-flex flex-column justify-content-between"
                style={{ padding: "16.8px 0" }}
              >
                {events ? (
                  <p className="card-name">{e.name}</p>
                ) : (
                  <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
                )}
                <div className="d-flex flex-column" style={{ gap: "25px" }}>
                  <div className="d-flex gap-2 align-items-center">
                    <svg
                      width="28"
                      height="27"
                      viewBox="0 0 28 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24.3764 0.705872H21.9058V3.2647C21.9058 3.77646 21.4941 4.11764 21.0823 4.11764C20.6705 4.11764 20.2588 3.77646 20.2588 3.2647V0.705872H7.08231V3.2647C7.08231 3.77646 6.67055 4.11764 6.25878 4.11764C5.84702 4.11764 5.43525 3.77646 5.43525 3.2647V0.705872H2.96466C1.72937 0.705872 0.823486 1.8147 0.823486 3.2647V6.33528H27.1764V3.2647C27.1764 1.8147 25.6941 0.705872 24.3764 0.705872ZM0.823486 8.12646V23.7353C0.823486 25.2706 1.72937 26.2941 3.04702 26.2941H24.4588C25.7764 26.2941 27.2588 25.1853 27.2588 23.7353V8.12646H0.823486ZM8.1529 22.4559H6.17643C5.84702 22.4559 5.5176 22.2 5.5176 21.7735V19.6412C5.5176 19.3 5.76466 18.9588 6.17643 18.9588H8.23525C8.56466 18.9588 8.89407 19.2147 8.89407 19.6412V21.7735C8.81172 22.2 8.56466 22.4559 8.1529 22.4559ZM8.1529 14.7794H6.17643C5.84702 14.7794 5.5176 14.5235 5.5176 14.097V11.9647C5.5176 11.6235 5.76466 11.2823 6.17643 11.2823H8.23525C8.56466 11.2823 8.89407 11.5382 8.89407 11.9647V14.097C8.81172 14.5235 8.56466 14.7794 8.1529 14.7794ZM14.7411 22.4559H12.6823C12.3529 22.4559 12.0235 22.2 12.0235 21.7735V19.6412C12.0235 19.3 12.2705 18.9588 12.6823 18.9588H14.7411C15.0705 18.9588 15.4 19.2147 15.4 19.6412V21.7735C15.4 22.2 15.1529 22.4559 14.7411 22.4559ZM14.7411 14.7794H12.6823C12.3529 14.7794 12.0235 14.5235 12.0235 14.097V11.9647C12.0235 11.6235 12.2705 11.2823 12.6823 11.2823H14.7411C15.0705 11.2823 15.4 11.5382 15.4 11.9647V14.097C15.4 14.5235 15.1529 14.7794 14.7411 14.7794ZM21.3294 22.4559H19.2705C18.9411 22.4559 18.6117 22.2 18.6117 21.7735V19.6412C18.6117 19.3 18.8588 18.9588 19.2705 18.9588H21.3294C21.6588 18.9588 21.9882 19.2147 21.9882 19.6412V21.7735C21.9882 22.2 21.7411 22.4559 21.3294 22.4559ZM21.3294 14.7794H19.2705C18.9411 14.7794 18.6117 14.5235 18.6117 14.097V11.9647C18.6117 11.6235 18.8588 11.2823 19.2705 11.2823H21.3294C21.6588 11.2823 21.9882 11.5382 21.9882 11.9647V14.097C21.9882 14.5235 21.7411 14.7794 21.3294 14.7794Z"
                        fill="#005B6D"
                      />
                    </svg>

                    {events ? (
                      <p className="p-0 m-0 card-date">{formatDate(e.date)}</p>
                    ) : (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1.2rem", width: "100%" }}
                      />
                    )}
                  </div>
                  <div className="d-flex gap-2 align-items-center">
                    <svg
                      width="28"
                      height="27"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1239_393)">
                        <path
                          d="M11 0C7.13432 0 3.91016 3.11395 3.91016 7.08984C3.91016 8.60243 4.36468 9.95122 5.23699 11.2151L10.4574 19.3611C10.7107 19.7571 11.2898 19.7563 11.5426 19.3611L16.7857 11.1874C17.6392 9.98078 18.0898 8.56397 18.0898 7.08984C18.0898 3.1805 14.9093 0 11 0ZM11 10.3125C9.22311 10.3125 7.77734 8.86673 7.77734 7.08984C7.77734 5.31296 9.22311 3.86719 11 3.86719C12.7769 3.86719 14.2227 5.31296 14.2227 7.08984C14.2227 8.86673 12.7769 10.3125 11 10.3125Z"
                          fill="#4D8C99"
                        />
                        <path
                          d="M16.0387 14.8111L12.7932 19.8852C11.9531 21.195 10.0422 21.1907 9.20614 19.8864L5.95538 14.8125C3.09521 15.4737 1.33203 16.6852 1.33203 18.1328C1.33203 20.6449 6.31331 22 11 22C15.6867 22 20.668 20.6449 20.668 18.1328C20.668 16.6841 18.9023 15.4721 16.0387 14.8111Z"
                          fill="#4D8C99"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1239_393">
                          <rect width="22" height="22" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    {events ? (
                      <p className="p-0 m-0 card-location">{e.city}</p>
                    ) : (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1.2rem", width: "100%" }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
