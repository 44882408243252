import React, { useRef } from "react";
import { useEffect, useState } from "react";
import "./View.css";
import notAssign from "../../../imgs/icons8-id-not-verified-100.png";
import axios from "axios";
import { PermContactCalendar, School } from "@mui/icons-material";
import { Skeleton, Snackbar } from "@mui/material";
import getCookie from "../../../utiles/Cookie";
import { Link } from "react-router-dom";
const ViewApplication = ({ setName }) => {
  const [showFileInput, setShowFileInput] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [UpdateSuccess, setUpdateSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const checkBoxRef = useRef();
  const user = Boolean(getCookie("user-login-fps"))
    ? JSON.parse(getCookie("user-login-fps"))
    : null;
  const [Details, setDetails] = useState({});
  const [flagAttached, setFlagAttached] = useState(false);
  const [eventSelectedID, setEventSelectedID] = useState("");
  const [IsActive, setIsActive] = useState();
  const [events, setEvents] = useState([]);
  const handleEventChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedIsActive = selectedOption.getAttribute("data-is-active");
    setEventSelectedID(e.target.value);
    setIsActive(selectedIsActive);
  };
  //functions for handle International board Acceptance
  const handleChechboxChange = (event) => {
    setShowUpdateButton(false);
    const value = event.target.checked;
    setShowFileInput(value === true);
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setShowUpdateButton(true);
  };
  const handleRadioChange = (event) => {
    const value = event.target.value;
    setShowUpdateButton(false);
    setShowFileInput(value === "yes");
    if (value === "no") {
      setSelectedFile(null);
    }
  };
  const handleUpdateClick = async () => {
    const formData = new FormData();
    formData.append("board_acceptance_file", selectedFile);
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/application/board-acceptance/${Details._id}`,
        {
          method: "POST",
          redirect: "follow",
          body: formData,
          headers: {
            token: `Bearer ${user?.token}`,
          },
        }
      );
      // Read the response body once
      const responseData = await response.json();
      if (response) setLoading(false);
      if (!response.ok) {
        console.log(
          `Error: ${
            responseData.errors ||
            JSON.stringify(responseData.error) ||
            response.status
          }`
        );
      } else {
        setShowFileInput(false);
        setShowUpdateButton(false);
        setUpdateSuccess(true);
        setDetails(responseData.result.data);
        checkBoxRef.current.checked = false;
      }
    } catch (error) {
      console.error("File upload failed:", error);
    }
  };
  const handleCloseUpdateSucess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setUpdateSuccess(false);
  };

  // ------get_files
  const GetFiles = async (file) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/application/668b9d42a2757e3967c03999/file?type=${file}`,
        {
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${user?.token}`,
          },
          responseType: "blob",
        }
      );
      const contentType = response.headers["content-type"];
      const extension = contentType.split("/")[1];
      // Create a temporary link element to trigger the file download
      const downloadLink = document.createElement("a");
      // downloadLink.href = URL.createObjectURL(new Blob([response.data], { type: contentType }));
      const blob = new Blob([response.data], { type: contentType });
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `${file}.${extension}`;
      downloadLink.click();
      return response.data;
    } catch (e) {
      // console.log(e);
    }
  };

  // fetch all events
  useEffect(() => {
    const fetchEvents = async () => {

      const apiUrl = `${process.env.REACT_APP_API_URL}/event`;
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${user?.token}`,
          },
        });
        const data = response.data;
        setEvents(data.result);
        setEventSelectedID(user?.user.applications[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchEvents();
  }, []);
  // fetch details of events
  useEffect(() => {
    setDetails(null);
    const Applicationdetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/application/event/${eventSelectedID}`,
          {
            headers: {
              "Content-Type": "application/json",
              token: `Bearer ${user?.token}`,
            },
          }
        );
        const data = response.data.result;
        setDetails(data);
        setName(data.first_name + data.last_name);

        return data;
      } catch (e) {}
    };
    Applicationdetails();
  }, [eventSelectedID, setName, user?.token]);

  useEffect(() => {
    if (
      (Details?.applicationData?.international_exams ||
        Details?.international_exams) &&
      (Details?.applicationData?.international_exams.length > 0 ||
        Details?.international_exams.length > 0)
    ) {
      const isAttachedFileNotNull = Details?.applicationData.international_exams
        ? Details?.applicationData.international_exams.some(
            (item) => item?.attached_file !== null
          )
        : Details?.international_exams.some(
            (item) => item?.attached_file !== null
          );

      // Update the state
      if (isAttachedFileNotNull) {
        setFlagAttached(true);
      }
    }
  }, [Details?.applicationData?.international_exams]);
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={UpdateSuccess}
        autoHideDuration={5000}
        onClose={handleCloseUpdateSucess}
        message="International board Acceptance updated successfully."
      />

      <div className="view_section  flex-fill">
        <div className="event my-4 d-flex align-items-center gap-2">
          <h5 className="m-0 p-0 fw-bold px-2">Enter The Event</h5>
          <select value={eventSelectedID} onChange={handleEventChange}>
            <option value="" disabled>
              select Event
            </option>
            {events?.map((event) => (
              <option
                key={event._id}
                value={event._id}
                data-is-active={event.isActive ? "true" : "false"}
              >
                {event.name}
              </option>
            ))}
          </select>
        </div>
        {Details === null || Object.keys(Details).length !== 0 ? (
          <div className="container pb-5 pt-3">
            <div className="title text-start d-flex justify-content-between flex-wrap">
              <h2 className="fw-bold app-header">Application Details</h2>
              <div className="btn_div text-end ">
                {Details?.status === "Accepted" ? (
                  <h3
                    style={{ background: "green" }}
                    className="rounded-pill app-status p-3 py-1 fw-bold"
                  >
                    {Details?.status}
                  </h3>
                ) : (
                  <h3
                    style={{ background: "red" }}
                    className="rounded-pill app-status p-3 py-1 fw-bold"
                  >
                    {Details?.status}
                  </h3>
                )}
              </div>
            </div>

            <div className="d-flex gap-3 flex-wrap">
              <div className="card-info flex-fill">
                <h5 className="my-4 d-flex gap-3 align-items-center fw-bold">
                  <PermContactCalendar /> Personal Information
                </h5>
                <hr />
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex gap-2 flex-wrap align-items-center">
                    <label
                      htmlFor="firstname"
                      className="form-label lable_text "
                    >
                      First name
                    </label>
                    {Details ? (
                      <p>{user?.user.first_name}</p>
                    ) : (
                      <Skeleton
                        variant="text"
                        className="flex-fill "
                        sx={{ fontSize: "2rem" }}
                      />
                    )}
                  </div>

                  <div className="d-flex gap-2 flex-wrap align-items-center">
                    <label htmlFor="lastname" className="form-label lable_text">
                      Last name
                    </label>
                    {Details ? (
                      <p>{user?.user.last_name}</p>
                    ) : (
                      <Skeleton
                        variant="text"
                        className="flex-fill "
                        sx={{ fontSize: "2rem" }}
                      />
                    )}
                  </div>

                  <div className="d-flex gap-2 flex-wrap align-items-center">
                    <label
                      htmlFor="mobile_number"
                      className="form-label lable_text"
                    >
                      Phone number
                    </label>
                    {Details ? (
                      <p>{user?.user.mobile_number}</p>
                    ) : (
                      <Skeleton
                        variant="text"
                        className="flex-fill "
                        sx={{ fontSize: "2rem" }}
                      />
                    )}
                  </div>

                  <div className="d-flex gap-2 flex-wrap align-items-center">
                    <label htmlFor="email" className="form-label lable_text">
                      E-mail
                    </label>
                    {Details ? (
                      <p>{user?.user.email}</p>
                    ) : (
                      <Skeleton
                        variant="text"
                        className="flex-fill "
                        sx={{ fontSize: "2rem" }}
                      />
                    )}
                  </div>

                  {Details?.applicationData?.institution && (
                    <div className="d-flex gap-2 flex-wrap align-items-center">
                      <label htmlFor="insta" className="form-label lable_text">
                        Institution
                      </label>
                      {Details ? (
                        <p>{Details?.applicationData?.institution}</p>
                      ) : (
                        <Skeleton
                          variant="text"
                          className="flex-fill "
                          sx={{ fontSize: "2rem" }}
                        />
                      )}
                    </div>
                  )}
                  {/* ==position== */}
                  {Details?.applicationData?.position && (
                    <div className="d-flex gap-2 flex-wrap align-items-center">
                      <label
                        htmlFor="position"
                        className="form-label lable_text"
                      >
                        Current position
                      </label>
                      {Details ? (
                        <p>{Details?.applicationData?.position}</p>
                      ) : (
                        <Skeleton
                          variant="text"
                          className="flex-fill "
                          sx={{ fontSize: "2rem" }}
                        />
                      )}
                    </div>
                  )}
                  {/* ==year_study */}
                  {Details?.applicationData?.year_of_study && (
                    <div className="d-flex gap-2 flex-wrap align-items-center">
                      <label htmlFor="Phone" className="form-label lable_text">
                        Year of study
                      </label>
                      {Details ? (
                        <p>{Details?.applicationData.year_of_study}</p>
                      ) : (
                        <Skeleton
                          variant="text"
                          className="flex-fill "
                          sx={{ fontSize: "2rem" }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              {/* ============== Professional Information */}
              <div className="card-info flex-fill">
                <h5 className="my-4 d-flex gap-3 align-items-center fw-bold">
                  <School /> Professional Information
                </h5>
                <hr />

                <div className="d-flex gap-2 flex-column">
                  {/* GPA */}
                  {Details?.applicationData?.gpa && (
                    <div className="d-flex gap-2 flex-wrap align-items-center">
                      <label htmlFor="Gpa" className="form-label lable_text">
                        GPA
                      </label>
                      {Details ? (
                        <p>{Details?.applicationData?.gpa}</p>
                      ) : (
                        <Skeleton
                          variant="text"
                          className="flex-fill "
                          sx={{ fontSize: "2rem" }}
                        />
                      )}
                    </div>
                  )}
                  {Details?.applicationData?.out_of_gpa && (
                    <div className="d-flex gap-2 flex-wrap align-items-center">
                      <label htmlFor="GPA" className="form-label lable_text">
                        Out of
                      </label>
                      {Details ? (
                        <p>{Details?.applicationData?.out_of_gpa}</p>
                      ) : (
                        <Skeleton
                          variant="text"
                          className="flex-fill "
                          sx={{ fontSize: "2rem" }}
                        />
                      )}
                    </div>
                  )}
                  {/* SMLE== */}
                  {Details?.applicationData?.smle_taken !== undefined && (
                    <div className="d-flex gap-2 flex-wrap align-items-center">
                      <label htmlFor="SMLE" className="form-label lable_text">
                        Have you taken the Saudi Medical License Exam (SMLE)?
                      </label>
                      {Details ? (
                        <p>
                          {Details.applicationData?.smle_taken ? "Yes" : "No"}
                        </p>
                      ) : (
                        <Skeleton
                          variant="text"
                          className="flex-fill"
                          sx={{ fontSize: "2rem" }}
                        />
                      )}
                    </div>
                  )}

                  {Details?.applicationData?.smle_taken && (
                    <>
                      <div className="d-flex gap-2 flex-wrap align-items-center">
                        <label htmlFor="SMLE" className="form-label lable_text">
                          SMLE Score
                        </label>
                        {Details ? (
                          <p>
                            {Details.applicationData.smle_score ||
                              "No score provided"}
                          </p>
                        ) : (
                          <Skeleton
                            variant="text"
                            className="flex-fill"
                            sx={{ fontSize: "2rem" }}
                          />
                        )}
                      </div>

                      <div className="d-flex gap-2 flex-wrap align-items-center">
                        <label htmlFor="SMLE" className="form-label lable_text">
                          SMLE Attachment
                        </label>
                        {Details ? (
                          Details?.applicationData?.smle_attachment ||
                          Details?.smle_attachment ? (
                            <div
                              onClick={() => GetFiles("smle_attachment")}
                              className="d-flex border p-1 mt-2"
                              style={{ borderRadius: "6px", cursor: "pointer" }}
                            >
                              <svg
                                width="19"
                                height="20"
                                viewBox="0 0 19 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.0809 18.5417H6.91921C3.03213 18.5417 1.36963 16.7917 1.36963 12.7001V12.5917C1.36963 8.89173 2.75505 7.1084 5.85838 6.80006C6.17505 6.77506 6.47588 7.02506 6.50755 7.36673C6.53921 7.7084 6.30171 8.01673 5.96921 8.05006C3.48338 8.29173 2.55713 9.52506 2.55713 12.6001V12.7084C2.55713 16.1001 3.69713 17.3001 6.91921 17.3001H12.0809C15.303 17.3001 16.443 16.1001 16.443 12.7084V12.6001C16.443 9.5084 15.5009 8.27506 12.9675 8.05006C12.643 8.01673 12.3975 7.71673 12.4292 7.37506C12.4609 7.0334 12.738 6.77506 13.0705 6.8084C16.2213 7.09173 17.6305 8.8834 17.6305 12.6084V12.7167C17.6305 16.7917 15.968 18.5417 12.0809 18.5417Z"
                                  fill="#404547"
                                />
                                <path
                                  d="M9.5 13.1249C9.17542 13.1249 8.90625 12.8416 8.90625 12.4999V3.0166C8.90625 2.67493 9.17542 2.3916 9.5 2.3916C9.82458 2.3916 10.0938 2.67493 10.0938 3.0166V12.4999C10.0938 12.8416 9.82458 13.1249 9.5 13.1249Z"
                                  fill="#404547"
                                />
                                <path
                                  d="M12.1523 5.50003C12.0019 5.50003 11.8515 5.4417 11.7327 5.3167L9.5002 2.9667L7.2677 5.3167C7.03812 5.55837 6.65812 5.55837 6.42854 5.3167C6.19895 5.07503 6.19895 4.67503 6.42854 4.43337L9.08062 1.6417C9.3102 1.40003 9.6902 1.40003 9.91979 1.6417L12.5719 4.43337C12.8015 4.67503 12.8015 5.07503 12.5719 5.3167C12.461 5.4417 12.3027 5.50003 12.1523 5.50003Z"
                                  fill="#404547"
                                />
                              </svg>
                              <p
                                className="px-1 my-0"
                                style={{ fontSize: "14px" }}
                              >
                                SMLE Attachment
                              </p>
                            </div>
                          ) : (
                            <p>No SMLE attachment</p>
                          )
                        ) : (
                          <Skeleton
                            variant="text"
                            className="flex-fill"
                            sx={{ fontSize: "2rem" }}
                          />
                        )}
                      </div>
                    </>
                  )}

                  {/* cv_attach */}
                  <div className="">
                    <div className="d-flex gap-2 flex-wrap align-items-center">
                      <label className="form-label lable_text d-block">
                        Cv
                      </label>
                      {Details === null ? (
                        <Skeleton
                          variant="text"
                          className="flex-fill "
                          sx={{ fontSize: "2rem" }}
                        />
                      ) : Details?.applicationData?.cvPath === null ||
                        Details?.cvPath === null ? (
                        <p>No Cv</p>
                      ) : (
                        <button
                          onClick={() => GetFiles("cv")}
                          className="d-flex border p-1  mb-2"
                          style={{ borderRadius: "6px" }}
                        >
                          <svg
                            width="19"
                            height="20"
                            viewBox="0 0 19 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.0809 18.5417H6.91921C3.03213 18.5417 1.36963 16.7917 1.36963 12.7001V12.5917C1.36963 8.89173 2.75505 7.1084 5.85838 6.80006C6.17505 6.77506 6.47588 7.02506 6.50755 7.36673C6.53921 7.7084 6.30171 8.01673 5.96921 8.05006C3.48338 8.29173 2.55713 9.52506 2.55713 12.6001V12.7084C2.55713 16.1001 3.69713 17.3001 6.91921 17.3001H12.0809C15.303 17.3001 16.443 16.1001 16.443 12.7084V12.6001C16.443 9.5084 15.5009 8.27506 12.9675 8.05006C12.643 8.01673 12.3975 7.71673 12.4292 7.37506C12.4609 7.0334 12.738 6.77506 13.0705 6.8084C16.2213 7.09173 17.6305 8.8834 17.6305 12.6084V12.7167C17.6305 16.7917 15.968 18.5417 12.0809 18.5417Z"
                              fill="#404547"
                            />
                            <path
                              d="M9.5 13.1249C9.17542 13.1249 8.90625 12.8416 8.90625 12.4999V3.0166C8.90625 2.67493 9.17542 2.3916 9.5 2.3916C9.82458 2.3916 10.0938 2.67493 10.0938 3.0166V12.4999C10.0938 12.8416 9.82458 13.1249 9.5 13.1249Z"
                              fill="#404547"
                            />
                            <path
                              d="M12.1523 5.50003C12.0019 5.50003 11.8515 5.4417 11.7327 5.3167L9.5002 2.9667L7.2677 5.3167C7.03812 5.55837 6.65812 5.55837 6.42854 5.3167C6.19895 5.07503 6.19895 4.67503 6.42854 4.43337L9.08062 1.6417C9.3102 1.40003 9.6902 1.40003 9.91979 1.6417L12.5719 4.43337C12.8015 4.67503 12.8015 5.07503 12.5719 5.3167C12.461 5.4417 12.3027 5.50003 12.1523 5.50003Z"
                              fill="#404547"
                            />
                          </svg>
                          <p
                            className="px-1  my-0"
                            style={{ fontSize: "14px" }}
                          >
                            Cv File
                          </p>
                        </button>
                      )}
                    </div>
                    <div className="d-flex gap-2 align-items-center flex-wrap">
                      <label className="form-label lable_text">
                        Academic transcript
                      </label>

                      {Details == null || Details == undefined ? (
                        <Skeleton
                          variant="text"
                          className="flex-fill "
                          sx={{ fontSize: "2rem" }}
                        />
                      ) : Details?.applicationData?.transcriptPath === null ||
                        Details?.transcriptPath ? (
                        <p>No Academic transcript</p>
                      ) : (
                        <button
                          onClick={() => GetFiles("transcript")}
                          className="d-flex border p-1 "
                          style={{ borderRadius: "6px" }}
                        >
                          <svg
                            width="19"
                            height="20"
                            viewBox="0 0 19 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.0809 18.5417H6.91921C3.03213 18.5417 1.36963 16.7917 1.36963 12.7001V12.5917C1.36963 8.89173 2.75505 7.1084 5.85838 6.80006C6.17505 6.77506 6.47588 7.02506 6.50755 7.36673C6.53921 7.7084 6.30171 8.01673 5.96921 8.05006C3.48338 8.29173 2.55713 9.52506 2.55713 12.6001V12.7084C2.55713 16.1001 3.69713 17.3001 6.91921 17.3001H12.0809C15.303 17.3001 16.443 16.1001 16.443 12.7084V12.6001C16.443 9.5084 15.5009 8.27506 12.9675 8.05006C12.643 8.01673 12.3975 7.71673 12.4292 7.37506C12.4609 7.0334 12.738 6.77506 13.0705 6.8084C16.2213 7.09173 17.6305 8.8834 17.6305 12.6084V12.7167C17.6305 16.7917 15.968 18.5417 12.0809 18.5417Z"
                              fill="#404547"
                            />
                            <path
                              d="M9.5 13.1249C9.17542 13.1249 8.90625 12.8416 8.90625 12.4999V3.0166C8.90625 2.67493 9.17542 2.3916 9.5 2.3916C9.82458 2.3916 10.0938 2.67493 10.0938 3.0166V12.4999C10.0938 12.8416 9.82458 13.1249 9.5 13.1249Z"
                              fill="#404547"
                            />
                            <path
                              d="M12.1523 5.50003C12.0019 5.50003 11.8515 5.4417 11.7327 5.3167L9.5002 2.9667L7.2677 5.3167C7.03812 5.55837 6.65812 5.55837 6.42854 5.3167C6.19895 5.07503 6.19895 4.67503 6.42854 4.43337L9.08062 1.6417C9.3102 1.40003 9.6902 1.40003 9.91979 1.6417L12.5719 4.43337C12.8015 4.67503 12.8015 5.07503 12.5719 5.3167C12.461 5.4417 12.3027 5.50003 12.1523 5.50003Z"
                              fill="#404547"
                            />
                          </svg>
                          <p className="px-1 my-0" style={{ fontSize: "14px" }}>
                            Academic transcript file
                          </p>
                        </button>
                      )}
                    </div>
                  </div>
                  {/* Interest Areas * */}
                  {Details?.applicationData?.area_of_interest && (
                    <div className="d-flex gap-2 flex-wrap align-items-center">
                      <label
                        htmlFor="InterestAreas"
                        className="form-label lable_text"
                      >
                        Interest Areas
                      </label>
                      {Details ? (
                        <p>{Details?.applicationData?.area_of_interest}</p>
                      ) : (
                        <Skeleton
                          variant="text"
                          className="flex-fill "
                          sx={{ fontSize: "2rem" }}
                        />
                      )}
                    </div>
                  )}
                  <div className="d-flex gap-2 flex-wrap align-items-center">
                    <label className="form-label lable_text d-block">
                      International board Acceptance
                    </label>
                    {Details ? (
                      <form>
                        {Details?.international_board_acceptance === null ||
                        Details?.international_board_acceptance === false ||
                        Details?.international_board_acceptance ===
                          undefined ? (
                          <div>
                            <p className="mb-0">
                              Have you been accepted in any international
                              medical board?
                            </p>
                            <label className="fw-normal d-inline-flex align-item-center gap-2">
                              Yes
                              <input
                                defaultChecked={
                                  Details?.international_board_acceptance !==
                                    null &&
                                  Details?.international_board_acceptance !==
                                    false &&
                                  Details?.international_board_acceptance !==
                                    undefined
                                }
                                type="radio"
                                name="option"
                                value="yes"
                                onChange={handleRadioChange}
                              />
                            </label>
                            <label className="fw-normal d-inline-flex align-item-center gap-2">
                              No
                              <input
                                defaultChecked={
                                  Details?.international_board_acceptance ===
                                    null ||
                                  Details?.international_board_acceptance ===
                                    false ||
                                  Details?.international_board_acceptance ===
                                    undefined
                                }
                                type="radio"
                                name="option"
                                value="no"
                                onChange={handleRadioChange}
                              />
                            </label>
                          </div>
                        ) : (
                          <div className="d-inline-flex  align-items-center flex-wrap mb-2 row-gap-2">
                            <button
                              onClick={() => GetFiles("board_acceptance_path")}
                              className="d-flex border p-1  "
                              style={{ borderRadius: "6px" }}
                            >
                              <svg
                                width="19"
                                height="20"
                                viewBox="0 0 19 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.0809 18.5417H6.91921C3.03213 18.5417 1.36963 16.7917 1.36963 12.7001V12.5917C1.36963 8.89173 2.75505 7.1084 5.85838 6.80006C6.17505 6.77506 6.47588 7.02506 6.50755 7.36673C6.53921 7.7084 6.30171 8.01673 5.96921 8.05006C3.48338 8.29173 2.55713 9.52506 2.55713 12.6001V12.7084C2.55713 16.1001 3.69713 17.3001 6.91921 17.3001H12.0809C15.303 17.3001 16.443 16.1001 16.443 12.7084V12.6001C16.443 9.5084 15.5009 8.27506 12.9675 8.05006C12.643 8.01673 12.3975 7.71673 12.4292 7.37506C12.4609 7.0334 12.738 6.77506 13.0705 6.8084C16.2213 7.09173 17.6305 8.8834 17.6305 12.6084V12.7167C17.6305 16.7917 15.968 18.5417 12.0809 18.5417Z"
                                  fill="#404547"
                                />
                                <path
                                  d="M9.5 13.1249C9.17542 13.1249 8.90625 12.8416 8.90625 12.4999V3.0166C8.90625 2.67493 9.17542 2.3916 9.5 2.3916C9.82458 2.3916 10.0938 2.67493 10.0938 3.0166V12.4999C10.0938 12.8416 9.82458 13.1249 9.5 13.1249Z"
                                  fill="#404547"
                                />
                                <path
                                  d="M12.1523 5.50003C12.0019 5.50003 11.8515 5.4417 11.7327 5.3167L9.5002 2.9667L7.2677 5.3167C7.03812 5.55837 6.65812 5.55837 6.42854 5.3167C6.19895 5.07503 6.19895 4.67503 6.42854 4.43337L9.08062 1.6417C9.3102 1.40003 9.6902 1.40003 9.91979 1.6417L12.5719 4.43337C12.8015 4.67503 12.8015 5.07503 12.5719 5.3167C12.461 5.4417 12.3027 5.50003 12.1523 5.50003Z"
                                  fill="#404547"
                                />
                              </svg>
                              <p
                                className="px-1  my-0"
                                style={{ fontSize: "14px" }}
                              >
                                board Acceptance Attach
                              </p>
                            </button>

                            <label
                              className="input-checkBox d-flex align-item-center gap-1 fw-normal p-1"
                              style={{ fontSize: "14px" }}
                            >
                              <input
                                type="checkbox"
                                ref={checkBoxRef}
                                name="option"
                                onChange={handleChechboxChange}
                              />
                              Do you want to change the file?
                            </label>
                          </div>
                        )}
                        {showFileInput && (
                          <div>
                            <input
                              type="file"
                              accept=".pdf, image/*"
                              onChange={(e) => {
                                const file = e.target.files[0];
                                const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB
                                const allowedTypes = [
                                  "application/pdf",
                                  "image/jpeg",
                                  "image/png",
                                  "image/svg+xml",
                                  "image/webp",
                                ]; // Correct WebP and SVG MIME types

                                if (file) {
                                  // Check file type
                                  if (!allowedTypes.includes(file.type)) {
                                    alert(
                                      "Only PDF and image files (JPEG, PNG, SVG, WebP) are allowed."
                                    );

                                    setShowUpdateButton(false);
                                    e.target.value = null; // Reset the file input
                                    return;
                                  }

                                  // Check file size
                                  if (file.size > maxSizeInBytes) {
                                    alert("Max size 1 MB.");
                                    setShowUpdateButton(false);
                                    e.target.value = null; // Reset the file input
                                    return;
                                  }
                                  handleFileChange(e);
                                }
                              }}
                            />
                          </div>
                        )}
                        {showUpdateButton && (
                          <button
                            type="button"
                            className="btn mt-3 w-100"
                            onClick={handleUpdateClick}
                            disabled={loading}
                          >
                            {loading ? "Update ... " : "Update"}
                          </button>
                        )}
                      </form>
                    ) : (
                      <Skeleton
                        variant="text"
                        className="flex-fill "
                        sx={{ fontSize: "2rem" }}
                      />
                    )}
                  </div>
                  {/* Exams */}
                  <div className="d-flex gap-2 flex-wrap align-items-center">
                    <label className="form-label lable_text d-block">
                      International Exams
                    </label>
                    {Details === null ? (
                      <Skeleton
                        variant="text"
                        className="flex-fill "
                        sx={{ fontSize: "2rem" }}
                      />
                    ) : Details?.international_exams?.length == 0 ||
                      Details?.applicationData?.international_exams?.length ===
                        0 ? (
                      <p>No</p>
                    ) : (
                      <>
                        <p>Yes</p>
                        {Details?.applicationData?.international_exams?.map(
                          (exam, index) => (
                            <div className="row">
                              <div key={index} className="col-md-4">
                                <div className="mb-4">
                                  <label
                                    htmlFor="InterestAreas"
                                    className="form-label lable_text"
                                  >
                                    Name
                                  </label>
                                  <p>{exam.name}</p>
                                </div>
                              </div>
                              <div key={index} className="col-md-4">
                                <div className="mb-4">
                                  <label
                                    htmlFor="InterestAreas"
                                    className="form-label lable_text"
                                  >
                                    Score
                                  </label>
                                  <p>{exam.score}</p>
                                </div>
                              </div>
                              <div key={index} className="col-md-4">
                                <div className="mb-4">
                                  <label
                                    htmlFor="InterestAreas"
                                    className="form-label lable_text"
                                  >
                                    Status
                                  </label>
                                  <p>{exam.status}</p>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                        {flagAttached === true ? (
                          <>
                            <div className="mb-4">
                              <label
                                htmlFor="InterestAreas"
                                className="form-label lable_text"
                              >
                                International Exams Files Attached
                              </label>
                              <button
                                onClick={() => GetFiles("international_exams")}
                                className="d-flex border p-1 "
                                style={{ borderRadius: "6px" }}
                              >
                                <svg
                                  width="19"
                                  height="20"
                                  viewBox="0 0 19 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.0809 18.5417H6.91921C3.03213 18.5417 1.36963 16.7917 1.36963 12.7001V12.5917C1.36963 8.89173 2.75505 7.1084 5.85838 6.80006C6.17505 6.77506 6.47588 7.02506 6.50755 7.36673C6.53921 7.7084 6.30171 8.01673 5.96921 8.05006C3.48338 8.29173 2.55713 9.52506 2.55713 12.6001V12.7084C2.55713 16.1001 3.69713 17.3001 6.91921 17.3001H12.0809C15.303 17.3001 16.443 16.1001 16.443 12.7084V12.6001C16.443 9.5084 15.5009 8.27506 12.9675 8.05006C12.643 8.01673 12.3975 7.71673 12.4292 7.37506C12.4609 7.0334 12.738 6.77506 13.0705 6.8084C16.2213 7.09173 17.6305 8.8834 17.6305 12.6084V12.7167C17.6305 16.7917 15.968 18.5417 12.0809 18.5417Z"
                                    fill="#404547"
                                  />
                                  <path
                                    d="M9.5 13.1249C9.17542 13.1249 8.90625 12.8416 8.90625 12.4999V3.0166C8.90625 2.67493 9.17542 2.3916 9.5 2.3916C9.82458 2.3916 10.0938 2.67493 10.0938 3.0166V12.4999C10.0938 12.8416 9.82458 13.1249 9.5 13.1249Z"
                                    fill="#404547"
                                  />
                                  <path
                                    d="M12.1523 5.50003C12.0019 5.50003 11.8515 5.4417 11.7327 5.3167L9.5002 2.9667L7.2677 5.3167C7.03812 5.55837 6.65812 5.55837 6.42854 5.3167C6.19895 5.07503 6.19895 4.67503 6.42854 4.43337L9.08062 1.6417C9.3102 1.40003 9.6902 1.40003 9.91979 1.6417L12.5719 4.43337C12.8015 4.67503 12.8015 5.07503 12.5719 5.3167C12.461 5.4417 12.3027 5.50003 12.1523 5.50003Z"
                                    fill="#404547"
                                  />
                                </svg>
                                <p className="mx-3 my-0">File</p>
                              </button>
                            </div>
                          </>
                        ) : (
                          <p>no folder attached</p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container pb-5 pt-3">
            <div class="event-section gap-2 card-info w-100 text-center  d-flex justify-content-center align-items-center flex-column">
              <img
                width="100"
                height="100"
                src={notAssign}
                alt="id-not-verified"
              />
              <p class="message fw-bold p-0 m-0">
                You are not registered for this event.
              </p>
              {IsActive === "true" ? (
                <>
                  <p className="fw-bold p-0 m-0">You can register now:</p>
                  <Link
                    className="register-button fw-bold btn "
                    to="event-registeration"
                  >
                    Register Now
                  </Link>
                </>
              ) : (
                <>
                  <p className="fw-bold inactive p-0 m-0">
                    This event is inactive and registration is unavailable.
                  </p>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewApplication;
