import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import schema from "../../validation/even-registeration-validation.js";
import "./event-registeration-form.css";
import Joi from "joi";
import axios from "axios";
import getCookie from "../../utiles/Cookie.js";
const EventRegisteration = () => {
  const [formData, setFormData] = useState({
    job: "",
    studentIdNumber: "",
    studentIdFile: null,
    registrationNumber: "",
    professionalClassification: "",
    healthPractitionerType: "",
    other: "",
  });

  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState({});
  const [token, setToken] = useState({});
  useEffect(() => {
    const userDataCookie = getCookie("user-login-fps");
    setToken(localStorage.getItem("user-login-token"));

    if (userDataCookie) {
      setUserData(JSON.parse(userDataCookie));
    }
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    const fieldSchema = Joi.object({ [name]: schema.extract(name) });
    const { error } = fieldSchema.validate({ [name]: value });
    setErrors({ ...errors, [name]: error ? error.details[0].message : "" });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({ ...formData, studentIdFile: file });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { error } = schema.validate(formData, { abortEarly: false });
    if (error) {
      const validationErrors = {};
      error.details.forEach((detail) => {
        validationErrors[detail.path[0]] = detail.message;
      });
      setErrors(validationErrors);
      return;
    }

    const dataToSubmit = new FormData();
    for (const key in formData) {
      if (formData[key]) {
        dataToSubmit.append(key, formData[key]);
      }
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/application`,
        dataToSubmit,
        {
          headers: {
            "Content-Type": "Application/json",
            token: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 201) {
        alert("You have successfully registered to this event");
      }
    } catch (error) {
      if (error.response.status === 400) {
        alert("You have already submitted on this event");
      } else if (error.response.status === 401) {
        alert("You are not authorized, please login fisrt");
      }
    }
  };

  return (
    <>
      <div className="header"></div>
      <h1 className="heading-text text-center mt-5 mb-0">Registration</h1>
      <div className="primary-info row my-3 mx-0 mx-lg-5">
        <p className="primary-info__heading fs-4">Personal Information</p>
        <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
          <label className="form-label" htmlFor="firstName">
            First Name
          </label>
          <div id="firstName">{userData.first_name}</div>
        </div>
        <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
          <label className="form-label" htmlFor="middleName">
            Middle Name
          </label>
          <div id="middleName">{userData.middle_name}</div>
        </div>
        <div className="primary-info__details col-6 col-sm-4   my-2 mb-5 ">
          <label className="form-label" htmlFor="lastName">
            Last Name
          </label>
          <div id="lastName">{userData.last_name}</div>
        </div>
        <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
          <label className="form-label" htmlFor="gender">
            Gender
          </label>
          <div id="gender">{userData.gender}</div>
        </div>
        <div className="primary-info__details col-6 col-sm-4  my-2 mb-5  ">
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <div id="email">{userData.email}</div>
        </div>
        <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
          <label className="form-label" htmlFor="mobileNumber">
            Mobile Number
          </label>
          <div id="mobileNumber">{userData.mobile_number}</div>
        </div>
        <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
          <label className="form-label" htmlFor="nationalId">
            National Id
          </label>
          <div id="nationalId">{userData.national_id}</div>
        </div>
        <Form.Group controlId="job" className="col-12 col-6 col-sm-4 mb-5">
          <Form.Label className="form-label">Job</Form.Label>
          <Form.Control
            as="select"
            name="job"
            value={formData.job}
            onChange={handleChange}
          >
            <option value="">Select your job</option>
            <option value="Student">Student</option>
            <option value="Professional-Practitioner">
              Professional Practitioner
            </option>
            <option value="Health-Practitioner">Health Practitioner</option>
          </Form.Control>
          {errors.job && <p className="text-danger">{errors.job}</p>}
        </Form.Group>
      </div>
      <Form onSubmit={handleSubmit} className="mt-3 mx-0 mx-lg-5">
        {formData.job === "Student" && (
          <div className="row mx-1">
            <Form.Group
              controlId="studentIdNumber"
              className="col-12 col-sm-4 mb-5"
            >
              <Form.Label className="form-label">Student ID Number</Form.Label>
              <Form.Control
                type="text"
                name="studentIdNumber"
                value={formData.studentIdNumber}
                onChange={handleChange}
                placeholder="Enter your Student ID Number"
              />
              {errors.studentIdNumber && (
                <p className="text-danger">{errors.studentIdNumber}</p>
              )}
            </Form.Group>

            <Form.Group
              controlId="studentIdFile"
              className="col-12 col-sm-4 mb-5"
            >
              <Form.Label className="form-label">Student ID File</Form.Label>

              <Form.Control
                type="file"
                name="studentIdFile"
                onChange={handleFileChange}
              />
              {errors.studentIdFile && (
                <p className="text-danger">
                  "studentIdFile" is required, Only JPEG, PNG, or PDF files are
                  allowed.
                </p>
              )}
            </Form.Group>
          </div>
        )}
        <div className="row mx-1">
          {formData.job === "Professional-Practitioner" && (
            <>
              <Form.Group
                controlId="registrationNumber"
                className="col-12 col-sm-4 mb-5"
              >
                <Form.Label className="form-label">
                  Registration Number
                </Form.Label>
                <Form.Control
                  type="text"
                  name="registrationNumber"
                  value={formData.registrationNumber}
                  onChange={handleChange}
                  placeholder="Enter your Registration Number"
                />
                {errors.registrationNumber && (
                  <p className="text-danger">{errors.registrationNumber}</p>
                )}
              </Form.Group>

              <Form.Group
                controlId="professionalClassification"
                className="col-12 col-sm-4 mb-5"
              >
                <Form.Label className="form-label">
                  Professional Classification
                </Form.Label>
                <Form.Control
                  type="text"
                  name="professionalClassification"
                  value={formData.professionalClassification}
                  onChange={handleChange}
                  placeholder="Enter your Professional classification"
                />
                {errors.professionalClassification && (
                  <p className="text-danger">
                    {errors.professionalClassification}
                  </p>
                )}
              </Form.Group>
            </>
          )}

          {formData.job === "Health-Practitioner" && (
            <Form.Group
              controlId="healthPractitionerType"
              className="col-12 col-sm-4 mb-5"
            >
              <Form.Label className="form-label">
                Health Practitioner Type
              </Form.Label>
              <Form.Control
                as="select"
                name="healthPractitionerType"
                value={formData.healthPractitionerType}
                onChange={handleChange}
              >
                <option value="">Select one only</option>
                <option value="Psychiatry">Psychiatry</option>
                <option value="Nursing">Nursing</option>
                <option value="Psychology">Psychology</option>
                <option value="Social-Service">Social Service</option>
                <option value="other">Other</option>
              </Form.Control>
              {errors.healthPractitionerType && (
                <p className="text-danger">{errors.healthPractitionerType}</p>
              )}
            </Form.Group>
          )}
        </div>
        {formData.job === "Health-Practitioner" &&
          formData.healthPractitionerType === "other" && (
            <Form.Group controlId="other" className="col-12 col-sm-6 mb-5">
              <Form.Label className="form-label">Other</Form.Label>
              <Form.Control
                type="text"
                name="other"
                value={formData.other}
                onChange={handleChange}
                className="other"
                placeholder="Enter yourMessage"
              />
              {errors.other && <p className="text-danger">{errors.other}</p>}
            </Form.Group>
          )}

        
        <div className="w-100 d-flex justify-content-center my-5">
          <Button type="submit">REGISTER</Button>
        </div>
      </Form>
    </>
  );
};

export default EventRegisteration;
