import React, { useEffect, useState } from "react";
import Mission from "../../../imgs/Comp 1_3.gif"
import vision from "../../../imgs/Comp 1.gif"

// import { Link } from "react-router-dom";
import ImgAbout from "../../../imgs/who.png";
import { useTranslation } from "react-i18next";
import "./about.css";
import { Skeleton } from "@mui/material";

const About = () => {
  const [value, setValue] = useState();
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/admin-dashboard/about`, {
      headers: {
        "Content-Type": "application/json"
       },
    })
      .then((res) => res.json())
      .then((data) => {
        // Populate state with fetched data
        setValue(data.about);
      });
  }, []);
  // lang
  const { t, i18n } = useTranslation("global");
  const currentLanguage = i18n.language;

  return (
    <>
      <div className="about_section py-4 my-5" id="about">
        <div className="row ">
          <div className="col-md-6 img_side">
            <img src={ImgAbout} alt="." className="w-100 h-100" />
          </div>
          <div className="col-md-6 ">
            <div className="info m-3">
              <h3>
                <span>About </span>
                Us
              </h3>
              {value ? (
                <p>{value?.about}</p>
              ) : (
                <>
                  <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
                </>
              )}
              <p>Our Inspiration</p>
              <ul>
                {value ? (
                  value?.inspiration.map((i) => {
                    return (
                      <li style={{ listStyleType: "disc", fontWeight: "500" }}>
                        <p>{i}</p>
                      </li>
                    );
                  })
                ) : (
                  <>
                    <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
                    <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
                    <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="container about_icon my-5 py-4">
          <div className="row">
            <div
              className="col-md-6 text-center"
              style={{
                borderLeft: currentLanguage === "ar" ? "1px solid #414648" : "",
                borderRight:
                  currentLanguage === "ar" ? "" : "1px solid #414648",
              }}
            >
              <div className="box">
                <img src={vision} alt="our vision"/>
                <h3 className="my-4">
                  <span className={currentLanguage === "ar" ? "d-none" : ""}>
                    Our{" "}
                  </span>
                  Vision
                </h3>
                {value ? (
                  <p>{value?.vision}</p>
                ) : (
                  <>
                    <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
                    <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
                    <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
                  </>
                )}
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div className="box">
              <img src={Mission} alt="our Mission"/>


                <h3 className="my-3">
                  <span className={currentLanguage === "ar" ? "d-none" : ""}>
                    Our{" "}
                  </span>
                  Mission
                </h3>
                {value ? (
                  <p>{value?.mission}</p>
                ) : (
                  <>
                    <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
                    <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
                    <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
