import "./App.css";
import React, { useEffect, useState } from "react";
import Header from "./components/common/header/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Register from "./components/register/Register";
import Event from "./components/event/event.jsx";
import Profile from "./components/profile/Profile.jsx";
import Footer from "./components/common/footer/Footer";
import Home from "./components/home/Home";
import Login from "./components/Login/Login.jsx";
import EventRegisteration from "./components/event-registeration/event-registeration-form.jsx";
import Hackathon from "./components/hackathon-page/Hackathon.jsx";
import AuthLayout from "./components/common/authLayout/AuthLayout.jsx";
import SignUp from "./components/signUp/SignUp.jsx";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [scrollProgress, setScrollProgress] = useState(0);

  const handleScroll = () => {
    const totalHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const progress = (window.scrollY / totalHeight) * 100;
    setScrollProgress(progress);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className="scroll-progress-bar"
        style={{ height: `${scrollProgress}%` }}
      ></div>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/login">
            <AuthLayout>
              <Login />
            </AuthLayout>
          </Route>
          <Route path="/sign-up">
            <AuthLayout>
              <SignUp />
            </AuthLayout>
          </Route>
          <Route exact path="/Registration" component={Register} />
          <Route exact path="/event" component={Event} />
          <Route exact path="/event-registeration" component={EventRegisteration} />
          <Route exact path="/Hackathon" component={Hackathon} />
          <Route exact path='/profile' component={Profile} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
