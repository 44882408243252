import medicalBanner from "../../../imgs/medical-banner.png";
import logos from "../../../imgs/logos.png";

import "./AuthLayout.css";
const AuthLayout = ({ children }) => {
  return (
    <div className="row px-0 mx-0 ">
      <div className="col-lg-7 px-5 mx-0  d-flex flex-column justify-content-between">
        <div className="logo-container col-lg-3 p-0 m-0 w-100 d-flex justify-content-center mt-3">
          <img src={logos} alt="logo" className="logos" />
        </div>

        <div>{children}</div>
      </div>
      <img
        src={medicalBanner}
        alt="side banner"
        className="col-5 d-none d-lg-block d-xl-block p-0"
      />
    </div>
  );
};

export default AuthLayout;
