import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

const SignUp = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    gender: "",
    email: "",
    mobile_number: "",
    national_id: "",
    password: "",
    confirm_password: "",
  });

  const [errors, setErrors] = useState({});
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useHistory();

  const handleChange = (e) => {
    const { id, value } = e.target;

    setFormData({
      ...formData,
      [id]: value,
    });

    if (errors[id]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[id];
        return newErrors;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register`,
        formData
      );
      localStorage.setItem("user-login-token", response.data.token);
      setIsSuccess(true);
      setResponseMessage(response.data.message);
      navigate.push("/login");
    } catch (error) {
      if (error.response) {
        if (error.response.data.errors) {
          const errorObj = error.response.data.errors.reduce((acc, curr) => {
            acc[curr.path] = curr.msg;
            return acc;
          }, {});
          setErrors(errorObj);
        }

        setIsSuccess(false);
        setResponseMessage(
          error.response.data.message || "Something went wrong."
        );
      } else if (error.request) {
        setResponseMessage("No response received. Please try again.");
      } else {
        setResponseMessage("Error: " + error.message);
      }
    }
  };

  return (
    <div className="container-fluid p-0">
      <div className="row d-flex justify-content-center mt-3">
        <form className="w-100 d-flex mb-5 mt-5 flex-column" onSubmit={handleSubmit} >
          <h1 className="login-heading w-100 d-flex justify-content-start mb-5 fw-bold">
            Sign up
          </h1>

          {responseMessage && (
            <div
              className={`alert ${
                isSuccess ? "alert-success" : "alert-danger"
              }`}
              role="alert"
            >
              {responseMessage}
            </div>
          )}

          <div className="row d-flex justify-content-between">
            <div className="col-6 mb-3">
              <label htmlFor="first_name">
                First Name <span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${
                  errors.first_name ? "is-invalid" : ""
                }`}
                id="first_name"
                placeholder="Enter your first name"
                onChange={handleChange}
                value={formData.first_name}
                required
              />
              {errors.first_name && (
                <div className="invalid-feedback">{errors.first_name}</div>
              )}
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="middle_name">
                Middle Name <span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${
                  errors.middle_name ? "is-invalid" : ""
                }`}
                id="middle_name"
                placeholder="Enter your middle name"
                onChange={handleChange}
                value={formData.middle_name}
                required
              />
              {errors.middle_name && (
                <div className="invalid-feedback">{errors.middle_name}</div>
              )}
            </div>
          </div>
          <div className="row d-flex justify-content-between">
            <div className="col-6 mb-3">
              <label htmlFor="last_name">
                Last Name <span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${
                  errors.last_name ? "is-invalid" : ""
                }`}
                id="last_name"
                placeholder="Enter your last name"
                onChange={handleChange}
                value={formData.last_name}
                required
              />
              {errors.last_name && (
                <div className="invalid-feedback">{errors.last_name}</div>
              )}
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="gender">
                Gender <span className="required">*</span>
              </label>
              <select
                aria-label="select gender"
                id="gender"
                className={`form-control ${errors.gender ? "is-invalid" : ""}`}
                onChange={handleChange}
                value={formData.gender}
                required
              >
                <option value="" disabled>
                  Select gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
              {errors.gender && (
                <div className="invalid-feedback">{errors.gender}</div>
              )}
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="email">
                E-mail <span className="required">*</span>
              </label>
              <input
                type="email"
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                id="email"
                placeholder="Enter your email"
                onChange={handleChange}
                value={formData.email}
                required
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="mobile_number">
                Mobile Number <span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${
                  errors.mobile_number ? "is-invalid" : ""
                }`}
                id="mobile_number"
                placeholder="Enter your mobile number"
                onChange={handleChange}
                value={formData.mobile_number}
                required
              />
              {errors.mobile_number && (
                <div className="invalid-feedback">{errors.mobile_number}</div>
              )}
            </div>
            <div className="col-6 mb-3">
              <label htmlFor="national_id">
                National ID <span className="required">*</span>
              </label>
              <input
                type="text"
                className={`form-control ${
                  errors.national_id ? "is-invalid" : ""
                }`}
                id="national_id"
                placeholder="Enter your national ID"
                onChange={handleChange}
                value={formData.national_id}
                required
              />
              {errors.national_id && (
                <div className="invalid-feedback">{errors.national_id}</div>
              )}
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="password">
                Password <span className="required">*</span>
              </label>
              <input
                type="password"
                className={`form-control ${
                  errors.password ? "is-invalid" : ""
                }`}
                id="password"
                placeholder="Enter your password"
                onChange={handleChange}
                value={formData.password}
                required
              />
              {errors.password && (
                <div className="invalid-feedback">{errors.password}</div>
              )}
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="confirm_password">
                Confirm Password <span className="required">*</span>
              </label>
              <input
                type="password"
                className={`form-control ${
                  errors.confirm_password ? "is-invalid" : ""
                }`}
                id="confirm_password"
                placeholder="Confirm your password"
                onChange={handleChange}
                value={formData.confirm_password}
                required
              />
              {errors.confirm_password && (
                <div className="invalid-feedback">
                  {errors.confirm_password}
                </div>
              )}
            </div>
          </div>
          <button type="submit" className="login-button w-100 mt-3 p-2 fw-bold">
            Sign Up
          </button>
          <p className="mt-4 text-center">
            Already have an account? 
            <button
              type="button"
              className="sign-up"
              onClick={() => {
                navigate.push("/login");
              }}
            >
              Login
            </button>
          </p>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
