import { Link, NavLink } from "react-router-dom";
import "./BottomNav.css";
import "../SideNav/sideNav.css";
import { Lock, Logout, RemoveRedEye } from "@mui/icons-material";
import { removeCookie } from "../../../utiles/Cookie";

export default function BottomNav({ open, setOpen }) {
  return (
    <div className="side-nav position-fixed bottom-0 d-flex gap-5 justify-content-center navBottom py-2 w-100 d-none">
      <NavLink
        to="/profile"
        className="d-flex flex-column justify-content-center align-items-center text-center "
      >
        <RemoveRedEye />
        <p className="p-0 m-0">Application</p>
      </NavLink>
      <div
        role="button"
        onClick={() => setOpen(true)}
        className="d-flex flex-column justify-content-center align-items-center text-center"
      >
        <Lock className={` ${open ? "active" : ""}`} />
        <p className="p-0 m-0" style={open ? { color: "#d2ab6a" } : {}}>
          Change Password
        </p>
      </div>
      <Link
        to="/"
        className="d-flex flex-column justify-content-center align-items-center text-center"
        onClick={()=>    removeCookie("user-login-fps")}
      >
        <Logout />
        <p className="p-0 m-0">Log Out</p>
      </Link>
    </div>
  );
}
