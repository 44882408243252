import "./Hackathon.css";
import { Form, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import getCookie from "../../utiles/Cookie";
import { Alert } from "@mui/material";
import View from "./View";
import Apply from "./Apply";

const Hackathon = () => {
  const user = Boolean(getCookie("user-login-fps"))
  ? JSON.parse(getCookie("user-login-fps"))
  : null;


  const [register, setRegister] = useState(false);
  const [registerData, setRegisterData] = useState();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/hackathon`, {
      headers: {
        "Content-Type": "Application/json",
        token: `Bearer ${user.token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        data.data != null&&  setRegisterData(data.data)
        data.data != null ? setRegister(true) : setRegister(false);
      });
  },[]);
  return (
    <div className="h-100">
      <h1 className="heading-text text-center mt-5 mb-0">
        Hackathon Registration
      </h1>
      {register ? <View token={user.token} data={registerData}/> : <Apply token={user.token} />}
    </div>
  );
};

export default Hackathon;
