import { useEffect } from "react";

export default function CounterBox({ state }) {
  useEffect(() => {});

  return (
    <>
      {state.days === 0 ? <p>Time Up</p> : <></>}
      <div className="counter_box ">
        <div className="counter-container d-flex justify-content-center flex-wrap gap-3">
          <div className="counter-timer-wrapper " style={{ width: "5.5rem" }}>
            <h3 className="counter-timer">{state.days || "0"}</h3>
            <span>Days</span>
          </div>

          <div className="counter-timer-wrapper " style={{ width: "5.5rem" }}>
            <h3 className="counter-timer">{state?.hours || "0"}</h3>
            <span>Hours</span>
          </div>

          <div className="counter-timer-wrapper " style={{ width: "5.5rem" }}>
            <h3 className="counter-timer">{state?.minutes || "0"}</h3>
            <span>Minutes</span>
          </div>

          <div className="counter-timer-wrapper" style={{ width: "5.5rem" }}>
            <h3 className="counter-timer">{state?.seconds || "0"}</h3>
            <span>Seconds</span>
          </div>
        </div>
        {/* } */}
      </div>
    </>
  );
}
