import Joi from "joi";


const fileValidation = Joi.any().custom((value, helpers) => {
  if (value && value.type && ["image/jpeg", "image/png", "application/pdf"].includes(value.type)) {
    return value;
  }
  return helpers.message("Only JPEG, PNG, or PDF files are allowed.");
}).required().messages({
  "any.required": "A file is required."
});


const schema = Joi.object({
  job: Joi.string().required(),
  studentIdNumber: Joi.when("job", {
    is: "Student",
    then: Joi.string().min(15).required(),
    otherwise: Joi.optional(),
  }),
  studentIdFile: Joi.when("job", {
    is: "Student",
    then: fileValidation,
    otherwise: Joi.optional(),
  }),
  registrationNumber: Joi.when("job", {
    is: "Professional-Practitioner",
    then: Joi.string().min(5).required(),
    otherwise: Joi.optional(),
  }),
  professionalClassification: Joi.when("job", {
    is: "Professional-Practitioner",
    then: Joi.string().required(),
    otherwise: Joi.optional(),
  }),
  healthPractitionerType: Joi.when("job", {
    is: "Health-Practitioner",
    then: Joi.string().required(),
    otherwise: Joi.optional(),
  }),
  other: Joi.when("job", {
    is: "Health-Practitioner",
    then: Joi.when("healthPractitionerType", {
      is: "other",
      then: Joi.string().required(),
      otherwise: Joi.optional(),
    }),
    otherwise: Joi.optional(),
  }),
});

export default schema;
