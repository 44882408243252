import React from "react";
import { Link } from "react-router-dom";

import LogoFooter from "../../../imgs/logo_mod_new.svg";
import { useTranslation } from "react-i18next";

import "./footer.css";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  const { t } = useTranslation("global");

  return (
    <>
      <footer>
        <div className="container text-center py-5 pb-3">
          <Link to="/">
            <img src={LogoFooter} alt=".." className=" my-4" />
          </Link>
          {/* <h2 className="my-5">Future Physicians Summit!</h2> */}
          <div className="links d-flex align-items-center justify-content-center my-4">
            <Link to="/" className="mx-3">
              <p>{t("header.HOME")}</p>
            </Link>
            <HashLink smooth to="/#about" className="mx-3">
              <p>{t("header.about")}</p>
            </HashLink>
            <Link to="/event" className="mx-3">
              <p>{t("header.event")}</p>
            </Link>
            {/* <a href="/#" className="mx-3"><p>Contact</p></a> */}
          </div>
          <div className="links d-flex align-items-center justify-content-center my-4">
            <div className=" d-flex  justify-content-center">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_572_168)">
                  <path
                    d="M16.1 3C15.9659 3.65993 15.9659 4.34007 16.1 5H4.511L12.061 11.662L17.11 7.142C17.536 7.669 18.068 8.108 18.673 8.427L12.072 14.338L4 7.216V19H20V8.9C20.6599 9.03406 21.3401 9.03406 22 8.9V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H16.1ZM21 7C20.606 7 20.2159 6.9224 19.8519 6.77164C19.488 6.62087 19.1573 6.3999 18.8787 6.12132C18.6001 5.84274 18.3791 5.51203 18.2284 5.14805C18.0776 4.78407 18 4.39397 18 4C18 3.60603 18.0776 3.21593 18.2284 2.85195C18.3791 2.48797 18.6001 2.15726 18.8787 1.87868C19.1573 1.6001 19.488 1.37913 19.8519 1.22836C20.2159 1.0776 20.606 1 21 1C21.7956 1 22.5587 1.31607 23.1213 1.87868C23.6839 2.44129 24 3.20435 24 4C24 4.79565 23.6839 5.55871 23.1213 6.12132C22.5587 6.68393 21.7956 7 21 7Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_572_168">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <a href="mailto:FPS@moi.med.sa" className="mx-3">
                <p>FPS@moi.med.sa</p>
              </a>
            </div>
            {/* ==== */}
            <div className=" d-flex  justify-content-center">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_572_174)">
                  <path
                    d="M9.366 10.682C10.3043 12.3305 11.6695 13.6957 13.318 14.634L14.202 13.396C14.3442 13.1969 14.5543 13.0569 14.7928 13.0023C15.0313 12.9478 15.2814 12.9825 15.496 13.1C16.9103 13.8729 18.4722 14.3378 20.079 14.464C20.3298 14.4839 20.5638 14.5975 20.7345 14.7823C20.9052 14.9671 21 15.2094 21 15.461V19.923C21.0001 20.1706 20.9083 20.4094 20.7424 20.5932C20.5765 20.777 20.3483 20.8927 20.102 20.918C19.572 20.973 19.038 21 18.5 21C9.94 21 3 14.06 3 5.5C3 4.962 3.027 4.428 3.082 3.898C3.10725 3.6517 3.22298 3.42352 3.40679 3.25763C3.5906 3.09175 3.82941 2.99995 4.077 3H8.539C8.79056 2.99997 9.0329 3.09475 9.21768 3.26545C9.40247 3.43615 9.51613 3.67022 9.536 3.921C9.66222 5.52779 10.1271 7.08968 10.9 8.504C11.0175 8.71856 11.0522 8.96874 10.9977 9.2072C10.9431 9.44565 10.8031 9.65584 10.604 9.798L9.366 10.682ZM6.844 10.025L8.744 8.668C8.20478 7.50409 7.83535 6.26884 7.647 5H5.01C5.004 5.166 5.001 5.333 5.001 5.5C5 12.956 11.044 19 18.5 19C18.667 19 18.834 18.997 19 18.99V16.353C17.7312 16.1646 16.4959 15.7952 15.332 15.256L13.975 17.156C13.4287 16.9437 12.898 16.6931 12.387 16.406L12.329 16.373C10.3676 15.2567 8.74328 13.6324 7.627 11.671L7.594 11.613C7.30691 11.102 7.05628 10.5713 6.844 10.025Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_572_174">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <a href="https://wa.me/966112437781" className="mx-3">
                <p>(+966) 11 243 7781</p>
              </a>
            </div>
          </div>
          <div className="social_div py-3 ">
            <div className="d-flex align-items-center justify-content-between">
              <p>Copyright © 2024 Eventify. All rights reserved.</p>
              {/* <div className="d-flex">
                <svg className="mx-3" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3285 18V9.78996H14.2423L14.6794 6.58941H11.3285V4.54632C11.3285 3.61998 11.5995 2.98869 13.0062 2.98869L14.7974 2.98799V0.125307C14.4876 0.0872508 13.4243 0 12.1867 0C9.60248 0 7.83325 1.49127 7.83325 4.22934V6.58941H4.91064V9.78996H7.83325V18H11.3285Z" fill="white"/>
                </svg>
                
                <svg className="mx-3" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_156_851)">
                <path d="M20.4426 3.79875C19.6973 4.125 18.9032 4.34125 18.0752 4.44625C18.927 3.93875 19.5771 3.14125 19.8827 2.18C19.0886 2.6525 18.2117 2.98625 17.2773 3.1725C16.5232 2.37125 15.4485 1.875 14.276 1.875C12.0013 1.875 10.17 3.7175 10.17 5.97625C10.17 6.30125 10.1975 6.61375 10.2652 6.91125C6.84931 6.745 3.82677 5.11125 1.79629 2.6225C1.4418 3.23625 1.23386 3.93875 1.23386 4.695C1.23386 6.115 1.96664 7.37375 3.05892 8.1025C2.39879 8.09 1.75119 7.89875 1.20255 7.5975C1.20255 7.61 1.20255 7.62625 1.20255 7.6425C1.20255 9.635 2.62677 11.29 4.49441 11.6712C4.15996 11.7625 3.79545 11.8062 3.41716 11.8062C3.15411 11.8062 2.88856 11.7913 2.63929 11.7362C3.17165 13.36 4.6823 14.5538 6.47854 14.5925C5.08063 15.6838 3.30568 16.3412 1.38418 16.3412C1.04723 16.3412 0.724052 16.3263 0.400879 16.285C2.22092 17.4563 4.37791 18.125 6.70401 18.125C14.2648 18.125 18.3984 11.875 18.3984 6.4575C18.3984 6.27625 18.3921 6.10125 18.3833 5.9275C19.1988 5.35 19.884 4.62875 20.4426 3.79875Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_156_851">
                <rect width="20.0418" height="20" fill="white" transform="translate(0.400879)"/>
                </clipPath>
                </defs>
                </svg>
               
                <svg className="mx-3" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.5095 18.9001V12.7471C18.5095 9.72309 17.8882 7.41309 14.5212 7.41309C12.8978 7.41309 11.8156 8.33709 11.3747 9.21909H11.3346V7.68609H8.14795V18.9001H11.4749V13.3351C11.4749 11.8651 11.7354 10.4581 13.459 10.4581C15.1626 10.4581 15.1826 12.1171 15.1826 13.4191V18.8791H18.5095V18.9001Z" fill="white"/>
                <path d="M2.73682 7.68604H6.06375V18.9H2.73682V7.68604Z" fill="white"/>
                <path d="M4.40008 2.1001C3.33787 2.1001 2.47607 3.0031 2.47607 4.1161C2.47607 5.2291 3.33787 6.1531 4.40008 6.1531C5.4623 6.1531 6.32409 5.2291 6.32409 4.1161C6.32409 3.0031 5.4623 2.1001 4.40008 2.1001Z" fill="white"/>
                </svg>
                
                <svg className="mx-3" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_156_860)">
                <path d="M14.831 0H5.31119C2.69346 0 0.55127 2.24927 0.55127 5V15C0.55127 17.7498 2.69346 20 5.31119 20H14.831C17.4487 20 19.5909 17.7498 19.5909 15V5C19.5909 2.24927 17.4487 0 14.831 0ZM10.0711 14.1665C7.8801 14.1665 6.10443 12.3005 6.10443 10C6.10443 7.69849 7.8801 5.83325 10.0711 5.83325C12.2611 5.83325 14.0378 7.69849 14.0378 10C14.0378 12.3005 12.2611 14.1665 10.0711 14.1665ZM15.2278 5.83325C14.5699 5.83325 14.0378 5.27349 14.0378 4.58325C14.0378 3.89302 14.5699 3.33325 15.2278 3.33325C15.8856 3.33325 16.4177 3.89302 16.4177 4.58325C16.4177 5.27349 15.8856 5.83325 15.2278 5.83325Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_156_860">
                <rect width="19.0397" height="20" fill="white" transform="translate(0.55127)"/>
                </clipPath>
                </defs>
                </svg>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
