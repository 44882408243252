import React from "react"
import ReactDOM from "react-dom/client"
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import { I18nextProvider } from 'react-i18next';
// import i18n from 'i18next';
// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

import translationsEN from './i18n/en/en.json'
import translationsAR from './i18n/ar/ar.json'
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";



import App from "./App"


i18next.init({
  interpolation:{escapeValue :false},
  lng:"en",
  resources:{
    en:{
      global:translationsEN,
    },
    ar:{
      global:translationsAR,
    }
  }
})


const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
    <App />
    </I18nextProvider>

  </React.StrictMode>
  
)
// serviceWorker.unregister();
