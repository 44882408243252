import { useEffect, useState } from "react";
import ViewApplication from "./Applications/Applications";
import SideNav from "./SideNav/SideNav";
import "./profile.css";
import BottomNav from "./BottomNav/BottomNav";
import ChangePass from "./ChangePass/ChangePass";
import getCookie from "../../utiles/Cookie";
export default function Profile() {
  const [name, setName] = useState();
  const [open, setOpen] = useState(false);

  const [user, setUser] = useState({});
  useEffect(() => {
    setUser(
      (user) =>
        (user = Boolean(getCookie("user-login-fps"))
          ? JSON.parse(getCookie("user-login-fps"))
          : null)
    );
  });

  return (
    <>
      <div  className="d-flex profile">
        <SideNav
          name={user?.user?.first_name + " " + user?.user?.last_name}
          open={open}
          setOpen={setOpen}
        />
        <ViewApplication setName={setName} />
      </div>
      <BottomNav open={open} setOpen={setOpen} />
      <ChangePass open={open} setOpen={setOpen} />
    </>
  );
}
