import { useEffect } from "react";

export default function View({ token, data }) {
  useEffect(() => {
    console.log(data);
  }, []);
  return (
    <div className="primary-info row my-3 mx-0 mx-lg-5">
      <p className="primary-info__heading fs-4">
        your registration Information
      </p>
      <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
        <label className="form-label" htmlFor="achievements">
          achievements
        </label>
        <div id="achievements">{data.achievements}</div>
      </div>
      <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
        <label className="form-label" htmlFor="company">
          company
        </label>
        <div id="company">{data.company}</div>
      </div>
      <div className="primary-info__details col-6 col-sm-4   my-2 mb-5 ">
        <label className="form-label" htmlFor="education">
          education
        </label>
        <div id="education">{data.education}</div>
      </div>
      <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
        <label className="form-label" htmlFor="role">
          role
        </label>
        <div id="role">{data.role}</div>
      </div>
      <div className="primary-info__details col-6 col-sm-4  my-2 mb-5  ">
        <label className="form-label" htmlFor="skills">
          skills
        </label>
        <div id="skills">{data.skills}</div>
      </div>
      <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
        <label className="form-label" htmlFor="years_of_experience">
          years_of_experience
        </label>
        <div id="years_of_experience">{data.years_of_experience}</div>
      </div>
      <p className="primary-info__heading fs-4">your Team</p>
      {data.team.map((t, index) => {
        return (
          <>
            <p className="primary-info__heading  fw-bold">
              {index + 1}st member
            </p>
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="f_name">
              first name
              </label>
              <div id="f_name">{t.f_name}</div>
            </div>
            <div className="primary-info__details col-6 col-sm-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="f_name">
              last name
              </label>
              <div id="f_name">{t.f_name}</div>
            </div>
            <div className="primary-info__details col-6 col-sm-4   my-2 mb-5 ">
              <label className="form-label" htmlFor="email">
                email
              </label>
              <div id="email">{t.email}</div>
            </div>
            <div className="primary-info__details col-6 col-sm-4   my-2 mb-5 ">
              <label className="form-label" htmlFor="m_name">
              Middle Name 
              </label>
              <div id="m_name">{t.m_name}</div>
            </div>
          </>
        );
      })}
      {/* <Form.Group controlId="job" className="col-12 col-6 col-sm-4 mb-5">
          <Form.Label className="form-label">Job</Form.Label>
          <Form.Control
            as="select"
            name="job"
            value={formData.job}
            onChange={handleChange}
          >
            <option value="">Select your job</option>
            <option value="Student">Student</option>
            <option value="Professional-Practitioner">
              Professional Practitioner
            </option>
            <option value="Health-Practitioner">Health Practitioner</option>
          </Form.Control>
          {errors.job && <p className="text-danger">{errors.job}</p>}
        </Form.Group> */}
    </div>
  );
}
