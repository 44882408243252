import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import getCookie from "../../../utiles/Cookie";
import { Alert, Snackbar } from "@mui/material";

export default function ChangePass({ open, setOpen }) {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [msg, setMsg] = useState();
  const resetForm=()=>{
    setMsg(null)
    setOldPassword("")
    setNewPassword("")
  }
  const handleClose = () => {
    resetForm()
    setOpen(false);
  }; 
   const [openAlert, setOpenAlert] = React.useState(false);

  const handleClick = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };


  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/user/change-password`,
        {
          currentPassword: oldPassword,
          newPassword: newPassword,
        },
        {
          headers: {
            token: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      handleClick()
      handleClose(); 
     
      resetForm()
    } catch (error) {
      setMsg(error.response?.data.errors?error.response?.data.errors[0].msg: error.response?.data.message)
      console.error(
        "Error changing password:",
        error.response?.data || error.message
      );
      // You can show an error message here if needed
    }
  };
  const user = Boolean(getCookie("user-login-fps"))
    ? JSON.parse(getCookie("user-login-fps"))
    : null;
  return (
    <><Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}

      open={openAlert}
      autoHideDuration={5000}
      onClose={handleCloseAlert}
      message="Password changed successfully." /><Dialog
        open={open}
        onClose={handleCloseAlert}
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit,
        }}
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
            <InputLabel htmlFor="old-password">Old Password</InputLabel>
            <Input
              id="old-password"
              type={showOldPassword ? "text" : "password"}
              value={oldPassword}
              onChange={(e) => {setOldPassword(e.target.value)
                setMsg(null)

              }}
              endAdornment={<InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowOldPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showOldPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>} />
          </FormControl>
          <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
            <InputLabel htmlFor="new-password">New Password</InputLabel>
            <Input
              id="new-password"
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => {setNewPassword(e.target.value)
                setMsg(null)
              }}
              endAdornment={<InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>} />
          </FormControl>
          {msg && (
            <Alert severity="error" className="mt-3 w-100">
              {msg}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="btn-dialog">
            Cancel
          </Button>
          <Button type="submit" className="btn-dialog">
            Submit
          </Button>
        </DialogActions>
      </Dialog></>
  );
}
