import React , {useState ,useEffect} from 'react'
import { Link} from "react-router-dom"
import { useRef } from 'react';
import './register.css'

const Register = () => {

  const [errPhone, seterrPhone] = useState('');
  const [errYear, seterrYear] = useState('');
  const [errGpa, seterrGpa] = useState('');


  const Phone = useRef(null);
  const Year = useRef(null);
  const Gpa = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    const [SMLECheck, setSMLECheck] = useState('false');
    // exam_check
    const [examCheck, setexamCheck] = useState(false);

    

    // ======object exam array
    const [rows, setRows] = useState([{ name: '',status: '', score: 0,  attached_file: '' }]); // Initialize with one empty row

    const handleAddRow = () => {
      setRows([...rows, { name: '',status: '', score: 0, attached_file: '' }]); // Add a new empty row to the array
    };
  
    const handleChange = (index, field, value) => {
        if (field === 'score') {
        value = parseInt(value);
      }
      const updatedRows = [...rows];
      updatedRows[index][field] = value;
      setRows(updatedRows);
    };



    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [mobile_number, setmobile_number] = useState('');
    const [email, setEmail] = useState('');
    const [institution, setInstitution] = useState('');
    const [position, setPosition] = useState('');
    const [year_of_study, setYearstudy] = useState('');
    const [gpa, setGPA] = useState('');
    const [out_ofGpa, setout_ofGpa] = useState('');
    const [Inter_board, set_Inter_board] = useState(false);
    const [area_of_interest, setAreas] = useState('');
    const [cvFile, setCVFile] = useState('');
    const [transcript, settranscript] = useState('');
    // SMLE
    const [smle_score, setSmle_score] = useState(0);
    const [smle_attach, setSMLE_Attach] = useState('');


    const [flag_done, setFlag_done] = useState(false);


    // const toggleModal = () => {
    //   setShowModal(!showModal);
    // };
    // const [showModal, setShowModal] = useState(false);
    // const Modal = ({ showModal, toggleModal }) => {
    //   if (!showModal) {
    //     return null;
    //   }
    // }

// console.log(process.env.REACT_APP_API_URL);

// ==============submit function
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log("cv",cvFile);

        // const internationalExams = rows.map(row => ({
        //   name: row.name,
        //   status: row.status,
        //   score: row.score,
        //   attached_file: row.attached_file
        // })); 
        // console.log(Inter_board ,SMLECheck ,"cheeee");
        const formData = new FormData();
        formData.append("first_name",first_name);
        formData.append("last_name",last_name);
        formData.append("mobile_number",mobile_number);
        formData.append("email",email);
        formData.append("institution",institution);
        formData.append("position",position);
        formData.append("year_of_study",year_of_study);
        formData.append("gpa",gpa);
        formData.append("out_of_gpa",out_ofGpa);
        formData.append("area_of_interest",area_of_interest);
        formData.append("international_board_acceptance",Inter_board);


        // SMLE
        formData.append("smle_taken",SMLECheck);
        if(SMLECheck==='true'){
          
          formData.append("smle_score",smle_score);
          formData.append("smle_attachment",smle_attach);
        }
        if(cvFile.name !== undefined){
          formData.append("cv",cvFile);
        }
        if(transcript.name !== undefined){
          formData.append("transcript",transcript);
        }
        // console.log(examCheck ,"examCheck");
       if(examCheck === "yes"){
        // formData.append('international_exams',  internationalExams );
        rows.forEach((row, index) => {
          formData.append(`international_exams[${index}].name`, row.name);
          formData.append(`international_exams[${index}].status`, row.status);
          formData.append(`international_exams[${index}].score`, row.score);
          formData.append(`international_exams[${index}].attached_file`, row.attached_file);
        });
       }

        fetch(`${process.env.REACT_APP_API_URL}/application`, {
          method: 'POST',
      
          body:formData,
          redirect:"follow"
        })
        .then(response => {
          if (!response.ok) {
            throw response
          }
          else if(response.ok){
            setFlag_done(true);
            window.scrollTo(0, 0);
          }
          return response.json(); 
        })
        .then(data => {
          // console.log('Data received:', data);
          // console.log(data.message)
          
        })
        .catch(error => {
          if (error instanceof Response) {
            error.json().then(data => {
              if (data.errors && data.errors.length > 0) {
                data.errors.forEach((error) => {
                  // console.log('Error message:', error.msg);

                  // alert(error.msg);
                  if (error.path === 'mobile_number') {
                    Phone.current.focus();
                    Phone.current.scrollIntoView({ behavior: 'smooth' });

                    seterrPhone(error.msg)
                                        // seterrPhone("")
                                        seterrYear("")
                                        seterrGpa("")
                  } else if (error.path === 'year_of_study') {
                    Year.current.focus();
                    Year.current.scrollIntoView({ behavior: 'smooth' });
                    seterrYear(error.msg)
                    seterrPhone("")
                    // seterrYear("")
                    seterrGpa("")
                  } else if (error.path === 'gpa') {
                    Gpa.current.focus();
                    Gpa.current.scrollIntoView({ behavior: 'smooth' });
                    seterrGpa(error.msg)
                    seterrPhone("")
                    seterrYear("")
                    // seterrGpa("")
                  }

                  
                })}
                else{
                  seterrPhone("")
                  seterrYear("")
                  seterrGpa("")
                  console.error('Error:',data.message);
                  // console.log('Error message:', data.errors[0].msg);
                  alert(data.message);
                }

            });
          } else {
            console.error('Network error:', error);
          }
        });


        
      };






  return (
    <>

    <div className='register_section'>
        <div className='hero_register'>
        </div>
        {
          flag_done ===true? 
          <>
            <div className=' text-center py-5'>
              <div className=' d-flex     justify-content-center'>
              <h6 className='my-5 w-75 '>
              Dear Applicant
              <br/>
              <br/>


Thank you for applying to the Future Physicians Summit. This message serves as a receipt confirming that we have successfully received your application.<br/><br/>

As an applicant, you will be considered for participation in various workshops, presentations, and networking sessions at the summit. Additionally, your application will be reviewed for potential sponsorship opportunities provided by MOI Medical Services, aimed at supporting your medical education and career development.<br/><br/>

A confirmation email with further details will be sent to you. Please ensure to check your email regularly for updates.<br/><br/>

We look forward to welcoming you to the Future Physicians Summit.
</h6>
              </div>
              <Link to="/"><button className='btn_add'>Back To Home</button></Link>

          </div>

          </>
          :
          <div className='container py-5'>
          <div className='title text-center'>
              <h2>Registration</h2>
          </div>
          <form onSubmit={handleSubmit}>
          {/* <form> */}
  
          <h5 className='my-4'>Personal Information</h5>
          <div className='row'>
              {/* firstname */}
              <div className='col-md-6'>
              <div className="mb-4">
                  <label htmlFor="firstname" className="form-label lable_text">First name<span style={{color:"red"}}>*</span></label>
                  <input type="text" onChange={(e) => setFirstName(e.target.value)} required className="form-control input_text" id="firstname" placeholder='Enter your first name' aria-describedby="firstname"/>
              </div>
              </div>
              {/* lastname== */}
              <div className='col-md-6'>
              <div className="mb-4">
                  <label htmlFor="lastname" className="form-label lable_text">Last name<span style={{color:"red"}}>*</span></label>
                  <input type="text" onChange={(e) => setLastName(e.target.value)} required className="form-control input_text" id="lastname" placeholder='Enter your last name' aria-describedby="lastname"/>
              </div>
              </div>
              {/* phone-number */}
              <div className='col-md-6'>
              <div className="mb-4">
                  <label htmlFor="mobile_number" className="form-label lable_text">Phone number<span style={{color:"red"}}>*</span></label>
                  <input type="text" ref={Phone} onChange={(e) => setmobile_number(e.target.value)} required className="form-control input_text" id="mobile_number" name="mobile_number" placeholder='Enter mobile number' aria-describedby="mobile_number"/>
                  <span style={{color:"red"}}>{errPhone}</span>
              </div>
              </div>
              {/* email */}
              <div className='col-md-6'>
              <div className="mb-4">
                  <label htmlFor="email" className="form-label lable_text">E-mail<span style={{color:"red"}}>*</span></label>
                  <input type="email"  onChange={(e) => setEmail(e.target.value)} required className="form-control input_text" id="email" placeholder='Enter your email' aria-describedby="email"/>
              </div>
              </div>
              {/* institions */}
              <div className='col-md-6'>
              <div className="mb-4">
                  <label htmlFor="insta" className="form-label lable_text">Institution<span style={{color:"red"}}>*</span></label>
                  <input type="text" onChange={(e) => setInstitution(e.target.value)} required className="form-control input_text" id="insta" placeholder='Enter Institution' aria-describedby="insta"/>
              </div>
              </div>
              {/* ==position== */}
              <div className='col-md-6'>
              <div className="mb-4">
                  <label htmlFor="position" className="form-label lable_text">Current position<span style={{color:"red"}}>*</span></label>
                  <input type="text" onChange={(e) => setPosition(e.target.value)} required className="form-control input_text" id="position" placeholder='Enter current position' aria-describedby="position"/>
              </div>
              </div>
              {/* ==year_study */}
              <div className='col-md-6'>
              <div className="mb-4">
                  <label htmlFor="Phone" className="form-label lable_text">Year of study<span style={{color:"red"}}>*</span></label>
                  <input type="text" ref={Year} onChange={(e) => setYearstudy(e.target.value)} required className="form-control input_text" name="Phone" id="year" placeholder='Enter year of study' aria-describedby="year"/>
                  <span style={{color:"red"}}>{errYear}</span>
              </div>
              </div>
          </div>
          {/* ============== Professional Information */}
          <h5 className='my-4'> Professional Information</h5>
          <div className='row'>
              {/* GPA */}
              <div className='col-md-6'>
  
              <div className="mb-4">
                    <label htmlFor="Gpa" className="form-label lable_text">GPA<span style={{color:"red"}}>*</span></label>
                    <input type="text" ref={Gpa} onChange={(e) => setGPA(e.target.value)} required className="form-control input_text" id="GPA" name="Gpa" placeholder='Enter your GPA' aria-describedby="GPA"/>
                    <span style={{color:"red"}}>{errGpa}</span>
              </div>
              </div>
              <div className='col-md-6'>
              <div className="mb-4">
                    <label htmlFor="GPA" className="form-label lable_text">
                      Out of<span style={{ color: "red" }}>*</span>
                    </label>
                    <select className="form-select input_text" aria-label="Default select example"
                        onChange={(e) => setout_ofGpa(e.target.value)}
                        required
                        id="GPA"
                      aria-describedby="GPA"
                    >
                    <option value="">Select GPA</option>
                      <option value="5">5</option>
                      <option value="4">4</option>
                  </select>
                  </div>
              </div>
              {/* SMLE== */}
              <div className='col-md-6'>
              <div className="mb-4">
                  <label htmlFor="SMLE" className="form-label lable_text">Have you take the Saudi Medical License Exam (SMLE)?<span style={{ color: "red" }}>*</span></label>
                  <div className='d-flex my-3'>
                      <div>
                      <label className="form-check-label" htmlFor="yessml">Yes</label>
                      <input onChange={(e)=> setSMLECheck(e.target.value)}  className="form-check-input mx-2 " name="checkSMLE" type="radio" value='true' id="yessml"/>
                      </div>
                      {/* no */}
                      <div className='mx-3'>
                      <label className="form-check-label" htmlFor="nosml">No</label>
                      <input onChange={(e)=> setSMLECheck(e.target.value)}  className="form-check-input mx-2" name="checkSMLE" type="radio" id="nosml" value="false"/>
                      </div>
  
                  </div>
              </div>
              </div>
              {/* score_attatsh */}
              <div className='col-md-6'>
                {
                  SMLECheck === 'true' ?
                  <div className='row'>
                  <div className='col-md-6'>
                    <div className="mb-4">
  
                        <label htmlFor="SMLE_Score" className="form-label lable_text">Score</label>
                        <input type="number" onChange={(e) => setSmle_score(e.target.value)} className="form-control input_text" id="SMLE_Score" placeholder='Enter SMLE Score' aria-describedby="SMLE_Score"/>
                  </div>
                  </div>
                  <div className='col-md-6'>
                  <div className="mb-4">
                  <label className='form-label lable_text '>Attach file</label>
                  <input type="file" onChange={(e) => setSMLE_Attach(e.target.files[0])} className="form-control input_text custom-file-button" id="SMLE_Attach file"/>
                  </div>
                  </div>
    
                  </div>
                  :
                  <></>
                }
  
              </div>
              {/* cv_attach */}
              <div className='col-md-6'>
                <div className="row mb-4">
                    <div className='col-md-6 '>
                    <div className="mb-4">
                        <label className='form-label lable_text'>Cv</label>
                        <input type="file" accept=".pdf, image/*" onChange={(e) =>
                          //  setCVFile(e.target.files[0])
                           {const maxSizeInBytes = 30 * 1024 * 1024; 
                            if (e.target.files[0].size <= maxSizeInBytes) {
                              setCVFile(e.target.files[0])
                            } else {
                              // File format or size is not supported, handle the error
                              // For example, you can display an error message or reset the file input
                              alert('Unsupported file  size');
                              e.target.value = null; // Reset the file input
                            }
                          //  handleChange(index, 'attached_file', e.target.files[0])
                          }
                           
                           }  className="form-control input_text custom-file-button" id="inputGroupFile01"/>
                        {/* <span>{e.target.files[0]}</span> */}
                    </div>
                    </div>
                    <div className='col-md-6'>
                    <div className="mb-4">
                        <label className='form-label lable_text'>Academic transcript<span style={{color:"red"}}>*</span></label>
                        <input type="file" accept=".pdf" required onChange={(e) => 
                          
                          {const maxSizeInBytes = 30 * 1024 * 1024; 
                            if (e.target.files[0].size <= maxSizeInBytes) {
                              settranscript(e.target.files[0])
                            } else {
                              alert('Unsupported file  size');
                              e.target.value = null; // Reset the file input
                            }
                          }
                          
                          }  className="form-control input_text custom-file-button" id="inputGroupFile01"/>
                    </div>
                    </div>
                </div>
  
              </div>
              {/* Interest Areas * */}
              <div className='col-md-6'>
              <div className="mb-4">
                  <label htmlFor="InterestAreas" className="form-label lable_text">Interest Areas<span style={{color:"red"}}>*</span></label>
                  <input type="text"  onChange={(e) => setAreas(e.target.value)} required className="form-control input_text" id="InterestAreas" placeholder='Enter your Interest Areas' aria-describedby="InterestAreas"/>
              </div>
              </div>
              <div className='col-md-12'>
              <div className="mb-4">
                  <label  className="form-label lable_text d-block">International board Acceptance<span style={{ color: "red" }}>*</span></label>
                  <span className='d-block'>Have you been accepted in any international medical board?</span>
                  <div className='d-flex my-3'>
                      <div>
                      <label className="form-check-label" htmlFor="yes_board">Yes</label>
                      <input onChange={(e) => set_Inter_board(e.target.value)} className="form-check-input mx-2 " name="checkinternational_board" type="radio" value="true" id="yes_board"/>
                      </div>
                      {/* no */}
                      <div className='mx-3'>
                      <label className="form-check-label" htmlFor="No_board">No</label>
                      <input onChange={(e) => set_Inter_board(e.target.value)}  className="form-check-input mx-2" name="checkinternational_board" type="radio" id="No_board" value="false"/>
                      </div>
  
                  </div>
  
              </div>
              </div>
              {/* Exams */}
              <div className='col-md-6'>
              <div className="mb-4">
                  <label  className="form-label lable_text d-block">International Exams<span style={{ color: "red" }}>*</span></label>
                  <span className='d-block'>Do you have any international Medical Licensing Examinations?</span>
                    <div className='d-flex my-3'>
                      <div>
                      <label className="form-check-label" htmlFor="yes_exam">Yes</label>
                      <input onChange={(e) => setexamCheck(e.target.value)} className="form-check-input mx-2 " name="checkinternational" type="radio" value="yes" id="yes_exam"/>
                      </div>
                      {/* no */}
                      <div className='mx-3'>
                      <label className="form-check-label" htmlFor="No_exam">No</label>
                      <input onChange={(e) => setexamCheck(e.target.value)}  className="form-check-input mx-2" name="checkinternational" type="radio" id="No_exam" value="no"/>
                      </div>
                    </div>
  
              </div>
              </div>
              {/* ==position== */}
              <div className='col-md-12'>
                {
                  examCheck === "yes" ?
                  <>
                {rows.map((row, index) => (
                  <div  className='row' key={index}>
                    <div className='col-md-3'>
                      <div className="mb-4">
                        <label htmlFor="exam_name" className="form-label lable_text">Name</label>
                        <input type="text"  onChange={(e) => handleChange(index, 'name', e.target.value)}  className="form-control input_text" id="exam_name" placeholder='Enter exam name' aria-describedby="exam_name"/>
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className="mb-4">
                        <label htmlFor="exam_score" className="form-label lable_text">Score</label>
                        <input type="number"  onChange={(e) => handleChange(index, 'score', e.target.value)}  className="form-control input_text" id="exam_score" placeholder='Enter exam score' aria-describedby="scrore"/>
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className="mb-4">
                        <label  className="form-label lable_text">Pass/fail</label>
                        <select className="form-select input_text" aria-label="Default select example"
                              onChange={(e) => handleChange(index, 'status', e.target.value)}
                          >
                          <option value="">Select Pass/Fail</option>
                            <option value="pass">Pass</option>
                            <option value="fail">Fail</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className="mb-4">
                        <label  className="form-label lable_text">Attach file</label>
                        <input type="file" accept=".pdf, image/*" onChange={(e) =>
                          { const maxSizeInBytes = 30 * 1024 * 1024; 
                            if (e.target.files[0].size <= maxSizeInBytes) {
                              handleChange(index, 'attached_file', e.target.files[0]);
                            } else {
                              alert('Unsupported file format or size');
                              e.target.value = null; // Reset the file input
                            }
                          }}  
                           className="form-control input_text custom-file-button" id="exam_file"/>
                      </div>
                    </div>
                  </div>
                ))}
                  <div className='btn_Add_div text-end'>
                    <button type='button' className='btn_add' onClick={handleAddRow}>+</button>
                  </div>
                  </>
                  :<></>
                }
  
              </div>
  
              <div className='btn_div text-center my-4'>
                  <button type='submit' className='btn_submit' >REGISTER</button>
              </div>
          </div>
          </form>
        </div>
        }



    </div>
    </>
  )
}

export default Register
