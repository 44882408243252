import { Accordion } from "react-bootstrap";
import "./sideNav.css";
import { Lock, Logout, RemoveRedEye } from "@mui/icons-material";
import { NavLink, Link } from "react-router-dom";
import * as React from "react";
import { Skeleton } from "@mui/material";
import { removeCookie } from "../../../utiles/Cookie";
export default function SideNav({ name, open, setOpen }) {
  const [menu, setMenu] = React.useState(false);
  return (
    <div className="side-nav position-sticky top-0 ">
      <div className="d-flex justify-content-between align-items-center px-3">
        {name ? (
          <h2 className="text-light fw-bold text-center mt-5 ">{name}</h2>
        ) : (
          <Skeleton
            variant="text"
            className="w-100 flex-fill"
            sx={{ fontSize: "2rem" }}
          />
        )}
      </div>
      <div className="bg-light divider mx-auto"></div>
      <Accordion
        defaultActiveKey={["0"]}
        alwaysOpen
        className={`px-3 py-2 mt-5 ${menu ? "open-menu" : "close-menu"}`}
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>Applications</Accordion.Header>
          <Accordion.Body className="ps-3">
            <NavLink to="/profile" className="d-flex align-items-center gap-3">
              <RemoveRedEye className="icon" /> View
            </NavLink>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Setting</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3 ps-3">
            <div
              variant="outlined"
              onClick={() => setOpen(true)}
              role="button"
              className="d-flex align-items-center gap-3 "
              style={{ color: open ? "#d2ab6a" : undefined }}
            >
              <Lock className={`icon ${open ? "active" : ""}`} /> change
              password
            </div>
            <Link to="../" className="d-flex align-items-center gap-3"         onClick={()=>removeCookie("user-login-fps")}
            >
              <Logout className="icon" />
              Log Out
            </Link>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
